import _createClass from"@babel/runtime/helpers/createClass";import _classCallCheck from"@babel/runtime/helpers/classCallCheck";import _inherits from"@babel/runtime/helpers/inherits";import _possibleConstructorReturn from"@babel/runtime/helpers/possibleConstructorReturn";import _getPrototypeOf from"@babel/runtime/helpers/getPrototypeOf";import _slicedToArray from"@babel/runtime/helpers/slicedToArray";function _createSuper(Derived){var hasNativeReflectConstruct=_isNativeReflectConstruct();return function _createSuperInternal(){var Super=_getPrototypeOf(Derived),result;if(hasNativeReflectConstruct){var NewTarget=_getPrototypeOf(this).constructor;result=Reflect.construct(Super,arguments,NewTarget);}else{result=Super.apply(this,arguments);}return _possibleConstructorReturn(this,result);};}function _isNativeReflectConstruct(){if(typeof Reflect==="undefined"||!Reflect.construct)return false;if(Reflect.construct.sham)return false;if(typeof Proxy==="function")return true;try{Boolean.prototype.valueOf.call(Reflect.construct(Boolean,[],function(){}));return true;}catch(e){return false;}}import _regeneratorRuntime from"@babel/runtime/regenerator";
import{
ASYNC_STORAGE_KEY_USER,
ASYNC_STORAGE_KEY_USER_TOKEN}from
'@24i/nxg-core-utils/src/constants';
import{BackstageUserDataClient}from'@24i/nxg-integration-backstage';

import{Storage}from'@24i/nxg-sdk-quantum';
import{setUserDataToStorage}from'@24i/nxg-sdk-smartott-shared/src/context/UserData';
import{getRuntimeConfig}from'@24i/nxg-sdk-smartott/src/Application/initApp';
















var removeToken=function removeToken(){return _regeneratorRuntime.async(function removeToken$(_context){while(1)switch(_context.prev=_context.next){case 0:return _context.abrupt("return",Storage.removeItem(ASYNC_STORAGE_KEY_USER_TOKEN));case 1:case"end":return _context.stop();}},null,null,null,Promise);};

var getUserDataFromStorage=function getUserDataFromStorage(){var obj;return _regeneratorRuntime.async(function getUserDataFromStorage$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.next=2;return _regeneratorRuntime.awrap(
Storage.getItem(ASYNC_STORAGE_KEY_USER));case 2:obj=_context2.sent;return _context2.abrupt("return",
JSON.parse(obj||''));case 4:case"end":return _context2.stop();}},null,null,null,Promise);};

var removeUserDataFromStorage=function removeUserDataFromStorage(){return _regeneratorRuntime.async(function removeUserDataFromStorage$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:return _context3.abrupt("return",Storage.removeItem(ASYNC_STORAGE_KEY_USER));case 1:case"end":return _context3.stop();}},null,null,null,Promise);};

var performLogout=function performLogout(logoutUrl,details){var formBody;return _regeneratorRuntime.async(function performLogout$(_context4){while(1)switch(_context4.prev=_context4.next){case 0:
formBody=Object.entries(details).
map(function(_ref){var _ref2=_slicedToArray(_ref,2),key=_ref2[0],value=_ref2[1];return encodeURIComponent(key)+"="+encodeURIComponent(value);}).
join('&');_context4.prev=1;_context4.next=4;return _regeneratorRuntime.awrap(


fetch(logoutUrl,{
method:'POST',
headers:{
'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
},
body:formBody
}));case 4:_context4.next=6;return _regeneratorRuntime.awrap(

removeUserDataFromStorage());case 6:_context4.next=8;return _regeneratorRuntime.awrap(
removeToken());case 8:_context4.next=13;break;case 10:_context4.prev=10;_context4.t0=_context4["catch"](1);

console.error('Logout error:',_context4.t0);case 13:case"end":return _context4.stop();}},null,null,[[1,10]],Promise);};


export var CustomUserDataClient=function(_BackstageUserDataCli){_inherits(CustomUserDataClient,_BackstageUserDataCli);var _super=_createSuper(CustomUserDataClient);function CustomUserDataClient(){var _this;_classCallCheck(this,CustomUserDataClient);for(var _len=arguments.length,args=new Array(_len),_key=0;_key<_len;_key++){args[_key]=arguments[_key];}_this=_super.call.apply(_super,[this].concat(args));_this.
loginFlowID=function _callee(token,user){return _regeneratorRuntime.async(function _callee$(_context5){while(1)switch(_context5.prev=_context5.next){case 0:_context5.prev=0;_context5.next=3;return _regeneratorRuntime.awrap(

setUserDataToStorage(user));case 3:_context5.next=8;break;case 5:_context5.prev=5;_context5.t0=_context5["catch"](0);

console.error('Logout err:',_context5.t0);case 8:case"end":return _context5.stop();}},null,null,[[0,5]],Promise);};_this.



logoutFlowID=function _callee2(){var _flowIdRuntimeConfig$,_flowIdRuntimeConfig$2,_flowIdRuntimeConfig$3;var flowIdRuntimeConfig,userData,_flowIdRuntimeConfig$4,apiKey,userKey,secret,logoutUrl,details;return _regeneratorRuntime.async(function _callee2$(_context6){while(1)switch(_context6.prev=_context6.next){case 0:
flowIdRuntimeConfig=getRuntimeConfig();_context6.next=3;return _regeneratorRuntime.awrap(
getUserDataFromStorage());case 3:userData=_context6.sent;if(!(

flowIdRuntimeConfig!=null&&(_flowIdRuntimeConfig$=flowIdRuntimeConfig.custom)!=null&&(_flowIdRuntimeConfig$2=_flowIdRuntimeConfig$.features)!=null&&(_flowIdRuntimeConfig$3=_flowIdRuntimeConfig$2.logoutFlowId)!=null&&_flowIdRuntimeConfig$3.logoutUrl&&userData!=null&&userData.id)){_context6.next=9;break;}_flowIdRuntimeConfig$4=

flowIdRuntimeConfig.custom.features.logoutFlowId,apiKey=_flowIdRuntimeConfig$4.apiKey,userKey=_flowIdRuntimeConfig$4.userKey,secret=_flowIdRuntimeConfig$4.secret,logoutUrl=_flowIdRuntimeConfig$4.logoutUrl;

details={
apiKey:apiKey||'',
userKey:userKey||'',
secret:secret||'',
UID:userData.id
};_context6.next=9;return _regeneratorRuntime.awrap(

performLogout(logoutUrl,details));case 9:case"end":return _context6.stop();}},null,null,null,Promise);};return _this;}return _createClass(CustomUserDataClient);}(BackstageUserDataClient);




export var createCustomUserDataClient=function createCustomUserDataClient(params){return(
new CustomUserDataClient(params));};