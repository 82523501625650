/* eslint-disable no-case-declarations */
import {
    Asset,
    ASSET_TYPE,
    Episode,
    getIsPodcastEpisode,
    Season,
    Series,
} from '@24i/nxg-sdk-photon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useEffectWithCancelledFlag } from '@24i/nxg-sdk-smartott-shared/src/hooks/useEffectWithCancelledFlag';

interface ISeason {
    name: string;
    episodes: Asset[];
    isCurrent: boolean;
}

interface ISeasonsState {
    seriesId?: string;
    seasons?: ISeason[];
}

export interface ISeriesData {
    series?: Series;
    seasons?: ISeason[];
    currentEpisode?: Episode;
    nextEpisode?: Episode;
}

export const useSeriesDataManager = (asset: Asset | Episode | Series | undefined): ISeriesData => {
    const contentDataClient = useContentData();
    const [series, setSeries] = useState<Series>();
    const [seasonsState, setSeasons] = useState<ISeasonsState>({
        seriesId: undefined,
        seasons: undefined,
    });
    const { seasons } = seasonsState;
    const [currentEpisode, setCurrentEpisode] = useState();
    const [nextEpisode, setNextEpisode] = useState();
    const assetAsEpisode = asset as Episode;
    const { t } = useTranslation(['sott']);

    const seriesId =
        asset?.type === ASSET_TYPE.SERIES
            ? asset.id
            : assetAsEpisode?.seriesId || assetAsEpisode?.series;

    useEffectWithCancelledFlag(
        async ({ getIsCancelled }) => {
            setSeries(undefined);
            setSeasons({
                seriesId: undefined,
                seasons: undefined,
            });
            setCurrentEpisode(undefined);
            setNextEpisode(undefined);

            let finalSeries;
            let finalSeasons;
            let finalCurrentEpisode;
            let finalNextEpisode;
            let rawSeasons;

            if (asset?.isTrailer) return;

            switch (asset?.type) {
                case ASSET_TYPE.SERIES:
                    rawSeasons = await contentDataClient.fetchEpisodes(seriesId, t);
                    if (getIsCancelled()) {
                        return;
                    }

                    // Find first and second episode
                    if (rawSeasons.length && rawSeasons[0].episodes.length)
                        [finalCurrentEpisode, finalNextEpisode] = rawSeasons[0].episodes;

                    finalSeries = asset;
                    setSeries(finalSeries);
                    finalSeasons = rawSeasons.map((season: Season) => ({
                        name: season.name,
                        episodes: season.episodes,
                        isCurrent: !!season.episodes.find(
                            (episode: Episode) => episode.id === finalCurrentEpisode?.id
                        ),
                    }));
                    setSeasons({
                        seriesId,
                        seasons: finalSeasons,
                    });
                    break;
                case ASSET_TYPE.EPISODE:
                    const obtainSerieData = async () => {
                        const canObtainSeriesData =
                            assetAsEpisode.seriesId || typeof assetAsEpisode.series === 'string';

                        if (seriesId && seriesId === series?.id) {
                            setSeries(series);
                            return;
                        }
                        if (canObtainSeriesData) {
                            const isPodcastEpisode = getIsPodcastEpisode(asset);
                            const asetTypeToFetch = isPodcastEpisode
                                ? ASSET_TYPE.PODCAST_SERIES
                                : ASSET_TYPE.SERIES;
                            const serieData = await contentDataClient.fetchAsset({
                                id: seriesId,
                                type: asetTypeToFetch,
                            });
                            if (getIsCancelled()) {
                                return;
                            }
                            setSeries(serieData);
                        }
                    };

                    const obtainEpisodes = async () => {
                        if (seriesId && seriesId === seasonsState.seriesId) {
                            finalSeasons = seasonsState.seasons;
                            setSeasons({
                                seriesId,
                                seasons: finalSeasons,
                            });
                            return;
                        }
                        if (seriesId) {
                            const seasonsData = await contentDataClient.fetchEpisodes(seriesId, t);
                            if (getIsCancelled()) {
                                return;
                            }
                            finalSeasons = seasonsData.map((season: Season) => ({
                                name: season.name,
                                episodes: season.episodes,
                                isCurrent: !!season.episodes.find(
                                    (episode: Episode) => episode.id === asset?.id
                                ),
                            }));
                            setSeasons({
                                seriesId,
                                seasons: finalSeasons,
                            });
                        }
                    };

                    await Promise.all([obtainSerieData(), obtainEpisodes()]);
                    if (getIsCancelled()) {
                        return;
                    }

                    finalCurrentEpisode = asset;

                    const currentSeason = finalSeasons.find((season) => season.isCurrent);
                    const nextSeason = finalSeasons[finalSeasons.indexOf(currentSeason) + 1];
                    const isLastEpisode =
                        [...currentSeason.episodes].pop().episodeNumber ===
                        finalCurrentEpisode.episodeNumber;

                    if (isLastEpisode) {
                        /*
                         * If current episode is the last episode of a season, pick
                         * a first episode of the next season.
                         */
                        if (nextSeason) [finalNextEpisode] = nextSeason.episodes;
                    } else
                        finalNextEpisode = currentSeason.episodes.find(
                            (episode) =>
                                episode.episodeNumber === finalCurrentEpisode.episodeNumber + 1
                        );
                    break;
                default:
                    break;
            }

            setCurrentEpisode(finalCurrentEpisode);
            setNextEpisode(finalNextEpisode);
        },
        [asset]
    );

    return {
        series,
        seasons,
        currentEpisode,
        nextEpisode,
    };
};

export const getEpisodeSubtitle = (asset: Asset, seriesData: ISeriesData) => {
    const { seasonNumber, episodeNumber, title } = asset;
    let episodeSubtitle;
    if (asset?.type === ASSET_TYPE.EPISODE) {
        if (seasonNumber && episodeNumber) {
            episodeSubtitle = i18next.t('asset.series.episode.number', {
                seasonNumber,
                episodeNumber,
                episodeTitle: title,
            });
        } else if (seriesData) {
            episodeSubtitle = asset.title;
        }
    }
    return episodeSubtitle;
};
