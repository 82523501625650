import { decode } from 'base-64';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/min/locales';
import { I18nManager } from 'react-native';
import { allExceptNumbersRegex } from './constants';

export interface GradientColors {
    color?: string;
    colors: (string | number)[];
    percentages?: number[]; // range 0.0 - 1.0
}

export interface RgbaColor {
    r: number; // decimal 0 - 255
    g: number;
    b: number;
    a: number; // fraction 0.0 - 1.0
}

export interface GradientElement {
    angle?: number; // in degrees 0 - 360
    percentage?: number; // percent 0 - 100
    rgba: RgbaColor;
}

export const firstLetterUppercase = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const groupWith = <Item, Key extends keyof Item>(
    items: Item[],
    getKeyToGroupBy: (item: Item) => string | number
): Item[][] => {
    const itemsMap = items.reduce((acc, item) => {
        const key = getKeyToGroupBy(item);
        const currentValue = acc[key];

        return {
            ...acc,
            [key]: currentValue ? [...currentValue, item] : [item],
        };
    }, {} as Record<Key, Item[]>);

    return Object.values(itemsMap);
};

const parseGradientDeg = (string: string): number | undefined => {
    const match = /([0-9]+deg)/.exec(string);
    if (!match) return undefined;
    return parseInt(match[1], 10);
};

const parseGradientDirection = (string: string): number | undefined => {
    switch (string) {
        case 'to top':
            return 0;
        case 'to top right':
            return 45;
        case 'to right':
            return 90;
        case 'to bottom right':
            return 135;
        case 'to bottom':
            return 180;
        case 'to bottom left':
            return 225;
        case 'to left':
            return 270;
        case 'to top left':
            return 315;
        default:
            return undefined;
    }
};

const parseHexColor = (hexColor: string): RgbaColor | undefined => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
              a: 1.0,
          }
        : undefined;
};

const makeHexColor = (color: RgbaColor, android = false): string => {
    /* eslint-disable no-bitwise */
    const { r, g, b, a } = color;
    let hex =
        (r | (1 << 8)).toString(16).slice(1) +
        (g | (1 << 8)).toString(16).slice(1) +
        (b | (1 << 8)).toString(16).slice(1);
    // Don't apply opacity if opacity's already at 1
    if (a !== 1) {
        // multiply before convert to HEX
        const alphaHex = ((a * 255) | (1 << 8)).toString(16).slice(1);
        hex = android ? alphaHex + hex : hex + alphaHex;
    }
    return `#${hex}`;
    /* eslint-enable no-bitwise */
};

const parseRgbaColor = (rgba: string): RgbaColor | undefined => {
    const result = /^rgba?\(([0-9]+), *([0-9]+), *([0-9]+)(?:, *([0-9.]+))?\)$/.exec(rgba);
    return result
        ? {
              r: parseInt(result[1], 10),
              g: parseInt(result[2], 10),
              b: parseInt(result[3], 10),
              a: result[4] ? parseFloat(result[4]) : 1.0,
          }
        : undefined;
};

const makeRgbaColor = (color: RgbaColor): string => {
    const { r, g, b, a } = color;
    return `rgba(${r}, ${g}, ${b}, ${a.toFixed(1)})`;
};

const parseAnyColor = (color: string | null | undefined | RgbaColor): RgbaColor | undefined => {
    if (typeof color === 'string') {
        if (color.startsWith('rgb')) return parseRgbaColor(color);
        if (color.startsWith('#')) return parseHexColor(color);
    }
    if (typeof color === 'object') {
        return color as RgbaColor;
    }
    return undefined;
};

const parseGradientPercentage = (string: string): number | undefined => {
    const match = /([0-9.]+)%/.exec(string);
    if (!match) return undefined;
    return parseFloat(match[1]) / 100.0;
};

const rgbaWithOpacity = (color: RgbaColor, opacity = 1.0): RgbaColor => {
    const { r, g, b } = color;
    return { r, g, b, a: opacity };
};

const toCMYK = (color: RgbaColor): [number, number, number, number] => {
    let computedC = 0;
    let computedM = 0;
    let computedY = 0;
    let computedK = 0;

    const { r, g, b } = color;

    // BLACK
    if (r === 0 && g === 0 && b === 0) {
        computedK = 1;
        return [0, 0, 0, 1];
    }

    computedC = 1 - r / 255;
    computedM = 1 - g / 255;
    computedY = 1 - b / 255;

    const minCMY = Math.min(computedC, Math.min(computedM, computedY));

    computedC = (computedC - minCMY) / (1 - minCMY);
    computedM = (computedM - minCMY) / (1 - minCMY);
    computedY = (computedY - minCMY) / (1 - minCMY);
    computedK = minCMY;

    return [computedC, computedM, computedY, computedK];
};

// hex to cmyk converter http://www.javascripter.net/faq/hex2cmyk.htm
export const hexToCMYK = (string: string): [number, number, number, number] | undefined => {
    const color = parseAnyColor(string);
    return color ? toCMYK(color) : undefined;
};

const isLightRgbaColor = (color: RgbaColor, lessSensitive = false): boolean => {
    const { r, g, b } = color;

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    /*
     * Using the HSP value, determine whether the color is light or dark
     * EDIT: upped it from 127.5 to make it more prone to display white color
     */
    if (lessSensitive) {
        return hsp > 200;
    }

    return hsp > 127.5;
};

export function isLightColor(string: string, lessSensitive = false): boolean | undefined {
    const color = parseAnyColor(string);
    return color ? isLightRgbaColor(color, lessSensitive) : undefined;
}

export const rgbaToHexCore = (orig: string, android: boolean) => {
    const color = parseAnyColor(orig);
    if (!color) return orig;
    return makeHexColor(color, android);
};

// RN needs hex color in #RRGGBBAA format
export const rgbaToHex = (orig: string) => rgbaToHexCore(orig, false);

// Android needs hex color in #AARRGGBB format
export const rgbaToAndroidHex = (orig: string) => rgbaToHexCore(orig, true);

const isDenseYellow = (color: RgbaColor): boolean => {
    const cmyk = toCMYK(color);
    return cmyk[2] > 0.95 && cmyk[0] < 0.05 && cmyk[1] < 0.05 && cmyk[3] < 0.05;
};

const ANTI_DENSE_YELLOW_HEX = '#5e5215';
const ANTI_DENSE_YELLOW_COLOR = parseHexColor(ANTI_DENSE_YELLOW_HEX)!;

export const preventDenseYellow = (hex: string) => {
    const color = parseAnyColor(hex);
    if (!color) return hex;
    if (isDenseYellow(color)) return ANTI_DENSE_YELLOW_HEX;
    return hex;
};

export const hexToRgb = (hex: string): RgbaColor | null => {
    const color = parseAnyColor(hex);
    if (!color) return null;
    return isDenseYellow(color) ? ANTI_DENSE_YELLOW_COLOR : color;
};

export const parseGradient = (string?: string): GradientElement[] => {
    const regex =
        /(?<comma>, *)|(?<deg>[0-9]+deg)|(?<direction>to [a-z ]+)|(?<hex>#[0-9a-fA-F]+)|(?<rgba>rgba?\([0-9., ]+\))|(?<percent>[0-9.]+%)/g;
    if (!string) return [];

    const elements: GradientElement[] = [];
    let angle: number | undefined;
    let lastColor: RgbaColor | undefined;
    let pushedColor = false;

    while (true) {
        const match = regex.exec(string);
        if (!match) break;
        const { comma, deg, direction, hex, rgba, percent } = match?.groups ?? {};
        if (deg) {
            angle = parseGradientDeg(deg);
        } else if (direction) {
            angle = parseGradientDirection(direction);
        } else if (hex) {
            lastColor = parseHexColor(hex);
        } else if (rgba) {
            lastColor = parseRgbaColor(rgba);
        } else if (percent) {
            const percentage = parseGradientPercentage(percent);
            if (lastColor && Number.isFinite(percentage)) {
                elements.push({
                    angle,
                    rgba: lastColor,
                    percentage,
                });
                pushedColor = true;
            }
        } else if (comma) {
            if (lastColor && !pushedColor) {
                elements.push({
                    angle,
                    rgba: lastColor,
                });
            }
            lastColor = undefined;
            pushedColor = false;
        }
    }
    if (lastColor && !pushedColor) {
        elements.push({
            angle,
            rgba: lastColor,
        });
    }

    return elements;
};

const makeSimpleGradient = (color: RgbaColor): GradientElement[] => {
    return [
        {
            rgba: rgbaWithOpacity(color, 0),
        },
        {
            rgba: color,
        },
    ];
};

const normalizeGradient = (gradient: GradientElement[]): GradientElement[] => {
    let angle = gradient[0]?.angle ?? 180;
    const reverse = angle < 45 || angle > 215;
    if (reverse) {
        angle = (angle + 180 + 360) % 360;
    }
    const maxIndex = Math.max(1, gradient.length - 1);
    const withAdjustedPercentages = gradient.map((element, index) => {
        let { percentage = NaN } = element;
        if (!Number.isFinite(percentage)) {
            percentage = index / maxIndex;
        }
        if (reverse) {
            percentage = 1.0 - percentage;
        }
        return {
            angle,
            rgba: element.rgba,
            percentage,
        };
    });
    withAdjustedPercentages.sort((a, b) => a.percentage - b.percentage);
    return withAdjustedPercentages;
};

const BLACK_GRADIENT_ELEMENT: GradientElement = {
    rgba: { r: 0, g: 0, b: 0, a: 1 },
};

const makeGradientColors = (gradient: GradientElement[]): GradientColors => {
    const angle = gradient[0]?.angle ?? 180;
    const colors = gradient.map((e) => makeRgbaColor(e.rgba));
    const percentages = gradient.map((element) => Number(element.percentage));

    let color = `linear-gradient(${angle}deg`;
    gradient.forEach((_, index) => {
        color += `, ${colors[index]} ${(100 * percentages[index]).toFixed(0)}%`;
    });
    color += ')';

    return {
        color,
        colors,
        percentages,
    };
};

export const gradientColors = (string: string, opacity = 1) => {
    const color = hexToRgb(string);
    if (!color) return [];

    return [
        makeRgbaColor(rgbaWithOpacity(color, 0)),
        makeRgbaColor(rgbaWithOpacity(color, opacity)),
    ];
};

export const getGradientColors = (
    string?: string,
    expectsTransparentAtTop?: boolean
): GradientColors => {
    let elements = parseGradient(string);
    if (elements.length === 0) {
        elements = [BLACK_GRADIENT_ELEMENT];
    }
    if (elements.length === 1) {
        elements = makeSimpleGradient(elements[0].rgba);
    }
    const isTopToBottom = (elements[0].angle ?? 180) === 180;
    const isTransparentAtTop = elements[0].rgba.a < elements[elements.length - 1].rgba.a;
    const shouldFlipDirection =
        (expectsTransparentAtTop === true && !isTransparentAtTop) ||
        (isTransparentAtTop === false && expectsTransparentAtTop);
    if (shouldFlipDirection && isTopToBottom) {
        elements[0] = {
            ...elements[0],
            angle: 0,
        };
    }
    elements = normalizeGradient(elements);
    return makeGradientColors(elements);
};

export const hexToArgb = (str: string) => {
    if (str.length > 7) return str.slice(0, 1) + str.slice(-2) + str.slice(1, -2);
    return str;
};

export const transformColorToArgb = (color: string) => {
    let hex = color;
    if (color.match(/^rgb/)) {
        hex = rgbaToHex(color);
    }
    return hexToArgb(hex);
};

export enum CurrencyName {
    USD = 'USD',
    EUR = 'EUR',
    CRC = 'CRC',
    GBP = 'GBP',
    ILS = 'ILS',
    INR = 'INR',
    JPY = 'JPY',
    KRW = 'KRW',
    NGN = 'NGN',
    PHP = 'PHP',
    PLN = 'PLN',
    PYG = 'PYG',
    THB = 'THB',
    UAH = 'UAH',
    VND = 'VND',
    CAD = 'CAD',
    AUD = 'AUD',
}

const currencySymbols: Record<CurrencyName, string> = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong};
    CAD: 'CA$', // Canadian Dollar;
    AUD: 'AU$', // Australian Dollar;
};

export const getCurrencySymbol = (currencyName: CurrencyName) => currencySymbols?.[currencyName];

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

type TimeFormatter = (startDate: Date, endDate: Date, is12HourClock: boolean) => string;

let timeFormatter: TimeFormatter;

export const setCustomTimeFormatter = (formatter: TimeFormatter) => {
    timeFormatter = formatter;
};

export const timeText = (
    startDate: Date,
    endDate: Date,
    is12HourClock: boolean,
    isLiveEvent?: boolean,
    isLive?: boolean,
    isSingleRow?: boolean
) => {
    if (timeFormatter) {
        return timeFormatter(startDate, endDate, is12HourClock);
    }
    let startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    let endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();
    const startampm = startHours >= 12 ? ' PM' : ' AM';
    const endampm = endHours >= 12 ? 'PM' : 'AM';

    if (is12HourClock) {
        startHours %= 12;
        endHours %= 12;
    }

    const [startHoursString, endHoursString, startMinutesString, endMinutesString] = [
        startHours,
        endHours,
        startMinutes,
        endMinutes,
    ].map((value) => (value < 10 ? `0${value}` : value.toString()));
    const startTime = `${startHoursString}:${startMinutesString}${is12HourClock ? startampm : ''}`;
    const endTime = `${endHoursString}:${endMinutesString} ${is12HourClock ? endampm : ''}`;

    if (isLive && isSingleRow) {
        const ltrText = `${startTime} - ${endTime}`;
        const rtlText = `${endTime} - ${startTime}`;
        return I18nManager.isRTL ? rtlText : ltrText;
    }

    if (isLiveEvent) {
        return startTime;
    }
    return `${startTime} - ${endTime}`;
};

export const timeDateText = (
    startDate: Date,
    endDate: Date,
    is12HourClock: boolean,
    isLiveEvent?: boolean,
    isLive?: boolean,
    isSingleRow?: boolean,
    showOnlyHours?: boolean
) => {
    const date = new Date();
    const isNow = startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime();
    const timeAsText = timeText(
        startDate,
        endDate,
        is12HourClock,
        isLiveEvent,
        isLive,
        isSingleRow
    );
    const month = months[startDate.getMonth()].toUpperCase().substring(0, 3);
    const day = startDate.getDate();
    const weekDay = days[startDate.getDay()].toUpperCase().substring(0, 3);

    if (isLive && isSingleRow) {
        return `${i18n.t('date.now')} · ${timeAsText}`;
    }

    if ((isNow && !isLiveEvent) || showOnlyHours) {
        return timeAsText;
    }

    return `${weekDay}, ${month} ${day} · ${timeAsText}`;
};

export const dateTimeText = (
    startDate: Date,
    endDate: Date,
    is12HourClock: boolean,
    displayTimeOnly = false,
    isLiveEvent?: boolean
) => {
    const timeAsText = timeText(startDate, endDate, is12HourClock, isLiveEvent);

    if (displayTimeOnly) return timeAsText;

    const month = months[startDate.getMonth()].toUpperCase().substring(0, 3);
    const day = startDate.getDate();
    const year = startDate.getFullYear();
    return `${month} ${day}, ${year} · ${timeAsText}`;
};

export const dayOfWeek = (date: Date, day: number) =>
    days[new Date(+date + day * (1000 * 60 * 60 * 24)).getDay()];

export const monthName = (date: Date, day: number) =>
    months[new Date(+date + day * (1000 * 60 * 60 * 24)).getMonth()];

export const dayText = (day: number): string | string[] => {
    const now = new Date();
    /* eslint-disable no-nested-ternary */
    return day === 0
        ? 'today'
        : day === -1
        ? 'yesterday'
        : day === 1
        ? 'tomorrow'
        : [
              dayOfWeek(now, day),
              monthName(now, day),
              new Date(now.getTime() + day * 24 * 60 * 60000).getDate().toString(),
          ];
    /* eslint-enable no-nested-ternary */
};

export const dateDiffInDays = (a: Date, b: Date) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const formatDurationText = (ms: number): string => {
    let m: number;
    let s: number;

    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s %= 60;
    const h = Math.floor(m / 60);
    m %= 60;
    if (h === 0) {
        return `${m}m`;
    }

    if (m === 0 || h > 99 || m < 1) {
        return `${h}h`;
    }
    return `${h}h ${m}m`;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const isLessThenXDays = (numberOfDays: number, start: number) => {
    const daysInMs = numberOfDays * 24 * 60 * 60 * 1000;
    return Date.now() - daysInMs < start;
};

export const isNumberValue = (value: string) => !allExceptNumbersRegex.test(value);

export const validatePin = (inputPin: string, pinLength = 4) =>
    inputPin.length < pinLength || !isNumberValue(inputPin);

export const checkCorrectPin = (e: any) => {
    const value = e.key;
    if (!isNumberValue(value) && value !== 'Backspace' && value !== 'Delete') {
        e.preventDefault();
    }
};

export const getDurationString = (
    durationSeconds: number,
    t: (text: string) => string
): string | null => {
    if (!durationSeconds) return null;
    const minString = t('date.min');
    const totalMins = Math.round(durationSeconds / 60);
    const hrs = Math.floor(totalMins / 60);
    const mins = totalMins % 60;

    if (!hrs) return `${mins || 1} ${t(minString)}`;
    return `${hrs} ${t('date.h')} ${mins} ${minString}`;
};

// Function to decode JWT tokens (both Backstage token and the Cleeng token that is wrapped inside of it).
// The function code has been extracted from the decodedToken function (packages/core@utils/src/decodedToken.ts)
export const decodeBase64Token = (token: string) => {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload =
        base64 &&
        decodeURIComponent(
            decode(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        );
    const result = jsonPayload && JSON.parse(jsonPayload);

    return result;
};

export const isValidDateString = (date: string) => {
    if (typeof date !== 'string') return false;
    const timestamp = new Date(date).getTime();

    return !Number.isNaN(timestamp);
};

export const formatFullReleaseDate = (fullReleaseDate: string, currentLocale: string) => {
    const date = new Date(fullReleaseDate);
    moment.locale(currentLocale);

    return moment(date).format('ll');
};
