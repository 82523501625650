import React, { ComponentProps, ReactNode } from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { NavConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import IconLink from '../components/IconLink';
import getFooterStyles from '../styles';
import TextLink from '../components/TextLink';
import { FooterStylesGetter } from '../styles/types';
import { useNavigationConfigQuery } from '../../../hooks/query/useNavigationConfigQuery';
import { useCurrentWebScreenSize } from '../../../hooks/useCurrentWebScreenSize';

export interface FooterProps {
    styles?: FooterStylesGetter;
    renderIconLink?: (props: ComponentProps<typeof IconLink>) => ReactNode;
    renderTextLink?: (props: ComponentProps<typeof TextLink>) => ReactNode;
}

export const defaultRenderIconLink = (props: ComponentProps<typeof IconLink>) => (
    // eslint-disable-next-line react/destructuring-assignment
    <IconLink key={`${props.icon?.name}${props.index}`} {...props} />
);

export const defaultRenderTextLink = (props: ComponentProps<typeof TextLink>) => (
    // eslint-disable-next-line react/destructuring-assignment
    <TextLink key={`${props.link}${props.index}`} {...props} />
);

const Footer = ({
    styles: getStyles = getFooterStyles,
    renderIconLink = defaultRenderIconLink,
    renderTextLink = defaultRenderTextLink,
}: FooterProps) => {
    const { t } = useTranslation('sott');
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const footerIcons = useFeature('footer')?.icons;
    const { navigationConfig } = useNavigationConfigQuery();
    const { screenSize } = useCurrentWebScreenSize();

    // TODO: use these screens and their configs to display the menu items.
    const screens = (navigationConfig as NavConfigWeb)?.[screenSize].footer;

    return (
        <View style={styles.footerBackground}>
            <View style={styles.footerContainer}>
                {footerIcons && (
                    <View style={styles.iconsContainer}>
                        {footerIcons.map((icon, index) =>
                            renderIconLink({
                                icon,
                                index,
                                isLastItem: index === footerIcons.length - 1,
                            })
                        )}
                    </View>
                )}
                {screens && (
                    <View style={styles.itemsContainer}>
                        {screens.map((link, index) => renderTextLink({ link, index }))}
                    </View>
                )}
                <View style={styles.disclaimerContainer}>
                    <Text style={styles.disclaimerText} numberOfLines={2}>
                        {t('footer.disclaimer')}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export { getFooterStyles };
export default overridable(Footer, 'Footer');
