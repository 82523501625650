import { useState, useEffect } from 'react';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { IDENTITY_PROVIDERS } from '@24i/nxg-core-utils/src/constants';
import { SharedViewModelReturnType } from './types';

const useShared = (): SharedViewModelReturnType => {
    const userDataClient = useUserData();
    const identityProvider = useFeature('identityProvider');
    const [redirectSigninUrl, setRedirectSigninUrl] = useState<string>('');
    const [isUrlLoading, setIsUrlLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchServiceURL = async () => {
            const { redirectUrl: url } = await userDataClient.getOAuthUrl();
            setRedirectSigninUrl(url);
            setIsUrlLoading(false);
        };
        if (identityProvider?.enabled) {
            switch (identityProvider?.provider) {
                case IDENTITY_PROVIDERS.FLOWID:
                case IDENTITY_PROVIDERS.FLOWID_V2:
                    fetchServiceURL();
                    break;
                case IDENTITY_PROVIDERS.GENERIC:
                    break;
                default:
                    setIsUrlLoading(false);
                    break;
            }
        } else {
            setIsUrlLoading(false);
        }
    }, [identityProvider]);

    return {
        redirectSigninUrl,
        isUrlLoading,
    };
};

export default useShared;
