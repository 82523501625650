import { useImage } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { Image, Interactable, View, Icon } from '@24i/nxg-sdk-quarks';
import AssetLabel from '@24i/nxg-sdk-smartott/src/components/AssetLabel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleView, { getTitleViewStyles } from '../../../../TitleView';
import { getSliderTestId, SLIDER_TEST_IDS } from '../../../test-utils';
import { useStore } from '../../../../../context/ApplicationStore';
import { SliderItemComponentProps } from '../../../types';

const Item = (props: SliderItemComponentProps & { subtitle?: string }) => {
    const {
        styles,
        data: item,
        onPress: onItemPress,
        index,
        sliderIndex,
        isObscured,
        showMetadata = true,
        isGeoBlocked,
        subtitle,
    } = props;

    const { theme } = useTheme();
    const { getObscuredImage, getImageUrl } = useImage();
    const { t } = useTranslation();

    const image = item.heroLandscape || item.still || item.background || '';
    const obscuredImg = getObscuredImage(item);

    const { runtimeConfig } = useStore();

    const config = runtimeConfig;
    const enableDynamicSliderImageType = config?.app?.rows?.enableDynamicSliderImageType;

    const shouldEnableDynamicSliderImage = enableDynamicSliderImageType && item?.poster;

    const renderImage = ({ index: imageIndex, item: imageItem, style, source }) => (
        <Image
            testID={getSliderTestId(SLIDER_TEST_IDS.SLIDE_IMAGE, {
                scopeIndex: sliderIndex,
                index,
            })}
            priority={imageIndex <= 1} // preload first two slider images
            alt={imageItem.title}
            style={style}
            source={source}
        />
    );

    const renderGeoBlockIcon = (): JSX.Element => (
        <View style={styles.geoBlockIconContainer}>
            <Icon
                font={theme.icons.details.geoBlocked.iconFont}
                name={theme.icons.details.geoBlocked.iconName}
                size={16}
                color={theme.color.contrast1}
            />
        </View>
    );

    let metadata1 = subtitle;
    let assetTitle = item.title;

    if (item?.type === ASSET_TYPE.EPISODE) {
        const { seasonNumber, episodeNumber, title } = item;
        assetTitle = item?.seriesName || item.podcastName;
        const seasonNumberText = seasonNumber
            ? `${t('asset.series.seasonInitial')}${seasonNumber} `
            : '';
        const episodeNumberText = episodeNumber
            ? `${t('asset.series.episode.initial')}${episodeNumber} `
            : '';
        const episodeTitleText = (seasonNumber || episodeNumber) && title ? `- ${title}` : title;

        metadata1 = `${seasonNumberText}${episodeNumberText}${episodeTitleText}`;
    }

    return (
        <Interactable
            testID={getSliderTestId(SLIDER_TEST_IDS.SLIDE_CONTAINER_PRESSABLE, {
                index,
                scopeIndex: sliderIndex,
            })}
            key={item.id}
            style={styles.slideContainer}
            onPress={() => onItemPress && onItemPress()}
            // onMouseDown={(e) => e.preventDefault()}
        >
            {isObscured && (
                <View
                    style={{
                        ...styles.imageStyle,
                        ...styles.gradient,
                        ...{ justifyContent: 'center', alignItems: 'center' },
                    }}
                >
                    {renderImage({
                        index,
                        item,
                        style: [styles.imageStyle, styles.dynamicPosterImage],
                        source: obscuredImg,
                    })}
                    <Icon font="materialIcons" name="lock" size={89} color={theme.color.lighter2} />
                    {isGeoBlocked ? renderGeoBlockIcon() : undefined}
                </View>
            )}
            {!!image && !isObscured && (
                <>
                    <View
                        style={{
                            ...styles.imageStyle,
                            ...styles.gradient,
                        }}
                    />
                    {renderImage({
                        index,
                        item,
                        style: [
                            styles.imageStyle,
                            shouldEnableDynamicSliderImage && styles.dynamicLandscapeImage,
                        ],
                        source: getImageUrl(
                            {
                                imagePath: image,
                                ...(shouldEnableDynamicSliderImage
                                    ? {
                                          width: 1300,
                                          height: 730,
                                      }
                                    : { quality: 50 }),
                            },
                            item?.images?.heroLandscape
                                ? item?.images?.heroLandscape
                                : item?.images?.still
                        ),
                    })}
                    {shouldEnableDynamicSliderImage &&
                        renderImage({
                            index,
                            item,
                            style: [styles.imageStyle, styles.dynamicPosterImage],
                            source: getImageUrl(
                                {
                                    imagePath:
                                        item.heroLandscape || item.still || item.background || '',
                                    width: 375,
                                    height: 667,
                                },
                                item?.images?.poster
                            ),
                        })}
                    {isGeoBlocked ? renderGeoBlockIcon() : undefined}
                </>
            )}
            <View testid={`Slider.SwiperSlide.${index}.View.1`} style={styles.textHolder}>
                {!!item.assetLabel && (
                    <View style={styles.assetLabelContainer}>
                        <AssetLabel text={item.assetLabel} />
                    </View>
                )}
                {showMetadata && (
                    <TitleView
                        title={isObscured ? t('asset.blocked.title') : assetTitle || ''}
                        channelLogo={item.channelLogo}
                        startsAt={item?.startsAt}
                        endsAt={item?.endsAt}
                        subtitle={metadata1}
                        isInCarousel
                        styles={(propsTheme) => ({
                            ...getTitleViewStyles(propsTheme),
                            holder: styles.titleViewHolder,
                        })}
                    />
                )}
            </View>
        </Interactable>
    );
};

export default Item;
