/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, SyntheticEvent, useEffect, useState, memo } from 'react';
import StyleSheet from 'react-native-media-query';
import Img, { ImageLoaderProps } from 'next/image';
import { Icon } from '@24i/nxg-sdk-quarks';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import FallbackImage from './components/FallbackImage';
import { parseImgUrl } from './helpers';
import { ImageWithFallBackPropsWeb } from './types';

const isRetina = () => {
    if (typeof window === 'undefined') return false;
    const mediaQuery =
        '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)';
    if (window.devicePixelRatio > 1) return true;
    if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true;
    return false;
};

const retinaWidthMultiplier = isRetina() ? 0.5 : 1;

const imageLoader = ({ src, width, quality }: ImageLoaderProps): string =>
    `${src}&w=${width}&q=${quality || 75}`;

const ImageWithFallback = React.forwardRef<Element, ImageWithFallBackPropsWeb>(
    (props, _): ReactElement => {
        const {
            style,
            alt,
            onPress,
            onClick,
            source,
            resizeMode,
            pointerEvents,
            priority,
            children,
            fallbackImage = (theme: Theme) => (
                <Icon
                    size={48}
                    font="materialIcons"
                    name="image"
                    color={theme?.color?.primary1 || 'rgba(62, 61, 64, 1)'}
                />
            ),
            hasGradientAlready = true,
            hasFallbackGradient = true,
            noFallback = false,
            draggable,
            onMouseEnter,
            testID,
        } = props;

        const { src: imageSource, width, height, quality } = parseImgUrl(source);
        const [error, setError] = useState(false);
        const [loading, setLoading] = useState(true);
        const { theme } = useTheme();

        const { ids, styles } = StyleSheet.process([
            {
                overflow: 'hidden',
                zIndex: 0,
                height: '100%',
                width: '100%',
                // if position of the child is absolute this has to be explicitely relative
                // for child to be positioned properly
                position: 'relative',
            },
            pointerEvents === 'none' && { pointerEvents: 'none' },
            style,
            // use the loading background when the loading condition is met or when the fallback image condition is met
            loading && { backgroundColor: theme?.color?.darker1 || 'rgba(31, 31, 31, 1)' },
        ]);

        useEffect(() => {
            // evaluate all the other conditions to not display the loading state
            // except the loading of the image
            if (children || ((error || !imageSource) && !noFallback)) setLoading(false);
        }, [error, imageSource, noFallback]);

        const renderDefaultImage = (): ReactElement =>
            hasFallbackGradient ? (
                <FallbackImage
                    fallbackImage={fallbackImage}
                    hasGradientAlready={hasGradientAlready}
                />
            ) : (
                fallbackImage(theme)
            );

        const handleLoadingComplete = (): void => {
            setLoading(false);
        };

        const handleError = (_e: SyntheticEvent<HTMLImageElement, Event>): void => {
            setError(true);
        };

        const handleClick = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
            onPress?.(e);
            onClick?.(e);
        };

        return (
            <div data-media={ids} style={styles}>
                {!!imageSource && !error && (
                    <Img
                        loader={imageLoader}
                        objectFit={resizeMode || 'cover'}
                        src={imageSource}
                        onLoadingComplete={handleLoadingComplete}
                        alt={alt || ''}
                        sizes={
                            width
                                ? `(min-width: ${Number(width) * retinaWidthMultiplier}px) ${
                                      Number(width) * retinaWidthMultiplier
                                  }px, 100vw`
                                : '100vw'
                        }
                        layout="fill"
                        loading={priority ? undefined : 'lazy'}
                        width={width ?? undefined}
                        height={height ?? undefined}
                        priority={priority}
                        quality={quality ?? undefined}
                        onError={handleError}
                        onClick={handleClick}
                        draggable={draggable}
                        onMouseEnter={onMouseEnter}
                        // @ts-expect-error
                        testid={testID}
                    />
                )}
                {children}
                {(error || !imageSource) && !noFallback && renderDefaultImage()}
            </div>
        );
    }
);
ImageWithFallback.displayName = 'ImageWithFallback';

const MemoizedImage = overridable(memo(ImageWithFallback), 'ImageWithFallback');
export default MemoizedImage;
MemoizedImage.displayName = 'ImageWithFallback';
