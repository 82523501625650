import { Asset, ASSET_TYPE, Channel, PageSection, Theme } from '@24i/nxg-sdk-photon';
import { ISource } from '@24i/player-base';
import * as i18next from 'i18next';
import { EventType } from '@24i/nxg-sdk-gluons/src/utils/common';
import { GridProps } from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';
import {
    SingleRowMobileProps,
    SingleRowWebProps,
} from '@24i/nxg-sdk-gluons/src/components/containers/SingleRow/View/types';
import { ScrollViewProps, ViewStyle } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';
import { SliderComponentProps } from '../../components/Slider/types';
import { BannerCarouselTypes } from '../../components/BannerCarousel/types';
import { ErrorMessageProps } from '../../components/ErrorMessage/types';

export interface DynaRowStyles {
    app: ViewStyle;
    holder: ViewStyle;
    activityIndicator: ViewStyle;
    wrapper?: ViewStyle;
    browseTitleContainer?: ViewStyle;
    heroSliderTitleContainer?: ViewStyle;
    browseTitle?: ViewStyle;
}

export interface DynaRowStylesNative extends DynaRowStyles {
    emptyBox?: ViewStyle;
    minHeightSingle?: ViewStyle;
    minHeightDouble?: ViewStyle;
    minHeightBanner?: ViewStyle;
    gridContainer?: ViewStyle;
    headerContainer?: ViewStyle;
    castIcon?: ViewStyle;
}

export type DynaRowStylesWeb = DynaRowStyles;

export type CustomAsset = {
    channel?: { type?: ASSET_TYPE; channel: Channel } | Channel;
} & Asset;

export interface TopBarDynaRowProps {
    styles?: DynaRowStylesNative;
    headerTransparent: boolean;
    insets?: EdgeInsets;
}

export interface UseTranslationOptions {
    i18n?: i18next.i18n;
    useSuspense?: boolean;
    wait: boolean;
}

export interface DynaRowModalProps {
    title: string;
    subtitle: string;
    onAction?: () => void;
}

export enum DynaRowScreenModeEnum {
    BASIC_DISPLAY = 'basic_display_mode',
    TOP_BAR = 'top_bar_mode',
}

export interface PlayPodcastFromDetailPage {
    playPodcastFromDetailPage?: boolean;
}

export interface DynaRowScreenProps extends PlayPodcastFromDetailPage {
    pageId?: string;
    sections?: PageSection[];
    disableDefaultHeaderBehaviour?: boolean;
    snapScrollViewProps?: ScrollViewProps;
    mode?: DynaRowScreenModeEnum;
    focusUpRef?: React.MutableRefObject<null>;
    goToPlayer?: (asset: Asset) => void;
    handleOnLayout?: (e: EventType, index: number, section: PageSection) => void;
    hideLogo?: (shouldHide: boolean) => boolean;
    goToDetails?: (item: Asset) => void;
    styles?: (theme: Theme) => DynaRowStylesNative | DynaRowStyles;
    renderBannerCarousel?: (props: BannerCarouselTypes) => JSX.Element;
    renderSingleRow?: (props: SingleRowMobileProps | SingleRowWebProps) => JSX.Element;
    renderSlider?: (props: SliderComponentProps) => JSX.Element;
    renderPackshotGrid?: (props: GridProps) => JSX.Element;
    renderErrorMessage?: (props: ErrorMessageProps) => JSX.Element;
    onLinkPress?: (item: Asset) => void;
    onFetchStream?: (asset: Asset) => Promise<ISource>;
    hideTitle?: boolean;
    hasScreenType?: boolean;
}

export interface PackshotPressType {
    section: PageSection;
    asset: Asset;
    onPurchaseAsset?: (asset: Asset) => void;
    playPodcastFromDetailPage?: boolean;
}

export interface DynaRowSharedProps {
    sections?: PageSection[];
    isLoading: boolean;
    hasError: boolean;
    onPackshotPress: ({
        onGoToPlayer,
        onLinkPress,
        onGoToDetails,
        section,
        asset,
        onPurchaseAsset,
        playPodcastFromDetailPage,
    }: {
        onGoToPlayer: () => void;
        onLinkPress: () => void;
        onGoToDetails: () => void;
        section: PageSection;
        asset: Asset;
        onPurchaseAsset?: (asset: Asset) => void;
        playPodcastFromDetailPage?: boolean;
        redirectToPodcastDetailPage?: (item: Asset) => void;
    }) => void;
    fetchNextPage: () => void;
    refetch: () => void;
    handleGoToPlayer?: (
        item: Asset | CustomAsset,
        handlePurchaseAsset?: (asset: Asset) => void,
        playPodcastFromDetailPage?: boolean
    ) => void;
    checkAssetBlocking?: (
        asset: Asset,
        sectionConfig: {
            actionForAllItems?: string | null | undefined;
        },
        callback: () => void,
        handlePurchaseAsset: (asset: Asset) => void,
        event?: EventType
    ) => Promise<void>;
    pageName?: string;
    pageTitle?: string;
}

export interface DynaRowViewProps extends DynaRowScreenProps {
    isLoading?: boolean;
    hasError?: boolean;
    handleSeeAllItemPress?: (section: PageSection) => void;
    handlePackshotPress?: ({ section, asset, onPurchaseAsset }: PackshotPressType) => void;
    pageName?: string;
    pageTitle?: string;
}

export interface DynaRowViewPropsMobile extends DynaRowViewProps {
    insets?: EdgeInsets;
    onError?: (error: unknown) => void;
    handleLinkPress?: (item: Asset) => Promise<void>;
    onFlatlistEndReached?: () => void;
}
