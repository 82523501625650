
import en from'@24i/prd-localization-files-prj1470cwc/dist/en.json';
import es from"./es/es.json";

export var i18nOptions={
resources:{
en:{sott:en},
es:{sott:es}
},
react:{
useSuspense:false
},
fallbackLng:'en'
};