import _defineProperty from"@babel/runtime/helpers/defineProperty";var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/cwc/config/customScreens.tsx";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}



import React from'react';
import{SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS}from'@24i/nxg-sdk-smartott/src/navigation/navigationConfig/screenConfigBuilders';
import{OpenModalLink}from'@24i/nxg-sdk-smartott/src/navigation/components/TopBarMenu/components/OpenModalLink/index.web';




import SettingsScreen from'@24i/nxg-sdk-smartott/src/screens/SettingsScreen';
import SigninScreen from'@24i/nxg-sdk-smartott/src/screens/SigninScreen';
import MvpdSignInScreen from'@24i/nxg-sdk-smartott/src/screens/MvpdSignInScreen';

export var CUSTOM_SCREEN_BUILDERS=_objectSpread(_objectSpread({},
SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS),{},{
buildSignInScreen:function buildSignInScreen(menuItem,context){var _context$features;
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSignInScreen(
menuItem,
context
);

var adobePrimetime=context==null?void 0:(_context$features=context.features)==null?void 0:_context$features.adobePrimetime;
var adobePrimetimeEnabled=adobePrimetime==null?void 0:adobePrimetime.enabled;

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:SigninScreen.Main
},
renderWebLink:function renderWebLink(_ref){var linkMenuItem=_ref.menuItem;return(
React.createElement(OpenModalLink,{
menuItem:linkMenuItem,

ModalComponent:
adobePrimetimeEnabled?
MvpdSignInScreen.Main:
SigninScreen.Main,__self:_this,__source:{fileName:_jsxFileName,lineNumber:33,columnNumber:17}}

));}});


},
buildSettingsScreen:function buildSettingsScreen(menuItem,context){
var originalBuild=SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSettingsScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:SettingsScreen.Main
}});

}});