import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView } from 'react-native';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import BackButton from '@24i/nxg-sdk-gluons/src/components/buttons/BackButton';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import AvatarPicker from '../components/AvatarPicker';
import { UPSERT_PROFILE_MODES } from '../constants';
import DeleteModal from '../components/DeleteModal';
import getDeleteModalStyles from '../components/DeleteModal/styles';
import NameInput from '../components/NameInput';
import getNameInputStyles from '../components/NameInput/styles';
import Section from '../components/Section';
import getSectionStyles from '../components/Section/styles';
import Title from '../components/Title';
import LoadingWrapper from '../../../components/LoadingWrapper/View/index';
import ViewingRestrictions from '../components/ViewingRestrictions';
import getViewingRestrictionsStyles from '../components/ViewingRestrictions/styles';
import getCreateProfileScreenStyles from '../styles';
import { CreateProfileWebScreenProps, UPSERT_PROFILE_TEST_IDS } from '../types';
import OnboardingHeader from '../../../navigation/components/OnboardingHeader';
import ToggleItem from '../../../components/ToggleItem';

const UpsertProfileScreen = (props: CreateProfileWebScreenProps) => {
    const {
        screenTitle,
        mode,
        isLoading,
        userProfile,
        contentRatings,
        primaryButtonText,
        shouldDisplayDeleteButton,
        CustomHeader = null,
        profileImageId,
        profileImages,
        styles: getStyles = getCreateProfileScreenStyles,
        isButtonDisabled,
        isOnboarding,
        onDeleteProfile = () => null,
        onProfileNameChange = () => null,
        onCreateProfile = () => null,
        onUpdateProfile = () => null,
        onViewingRestrictionChange,
        onViewingRestrictionsToggle,
        onPinProtectedChange,
        onProfileImageChange,
        onBack,
    } = props;
    const [isModalVisible, setModalVisible] = useState(false);
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const style = getStyles(theme, dimensions, isButtonDisabled, isOnboarding);
    const router = useRouter();
    const editMode = mode === UPSERT_PROFILE_MODES.EDIT;
    const isAgeRestrictionEnabled = contentRatings.length > 0;
    const contentRatingsForSelection = contentRatings.length > 1 ? contentRatings : [];
    const isPinProtectionEnabled = useFeature('pinProtection')?.enabled;

    const onBackDefault = () => {
        if (router?.back) {
            router.back();
        }
    };

    if (isLoading || (editMode && userProfile === null)) {
        return <Loader additionalStyles={style.loader} color={theme.color.textPrimary} />;
    }

    return (
        <SafeAreaView style={style.wrapper}>
            {isOnboarding && (
                <OnboardingHeader
                    title={screenTitle}
                    wrapperStyle={style.onboardingHeaderWrapper}
                />
            )}
            <View style={style.screenContent}>
                {!isOnboarding &&
                    (CustomHeader || (
                        <View style={style.headerContainerLandscape}>
                            <BackButton
                                onPress={onBack || onBackDefault}
                                icon={theme.icons.menu.back}
                                iconSize={16}
                                title={t('profiles.backButton')}
                                additionalTextStyles={style.backButtonText}
                                titleNumberOfLines={1}
                            />

                            <Text style={style.headerText}>{screenTitle}</Text>
                        </View>
                    ))}
                <Section styles={getSectionStyles}>
                    <Title
                        label={
                            mode === UPSERT_PROFILE_MODES.EDIT
                                ? 'profiles.name.label'
                                : 'profiles.name.labelTopic'
                        }
                    />
                    <NameInput
                        userProfile={userProfile}
                        onProfileNameChange={onProfileNameChange}
                        styles={getNameInputStyles}
                    />
                </Section>
                <Section styles={style.profileSection}>
                    <Title
                        label={
                            mode === UPSERT_PROFILE_MODES.EDIT
                                ? 'profiles.avatar.label'
                                : 'profiles.avatar.labelTopic'
                        }
                    />
                    <AvatarPicker
                        profileImageId={profileImageId}
                        profileImages={profileImages}
                        onProfileImageChange={onProfileImageChange}
                    />
                </Section>
                {isAgeRestrictionEnabled && (
                    <Section styles={style.pinToggleSection}>
                        <ToggleItem
                            title={t('profiles.ageRestriction.label')}
                            testID={UPSERT_PROFILE_TEST_IDS.VIEWING_RESTRICTIONS_SWITCH}
                            onValueChange={onViewingRestrictionsToggle}
                            defaultValue={typeof userProfile?.age === 'number'}
                        />
                        <ViewingRestrictions
                            contentRatings={contentRatingsForSelection}
                            styles={getViewingRestrictionsStyles}
                            userProfile={userProfile}
                            onChange={onViewingRestrictionChange}
                        />
                    </Section>
                )}
                {isPinProtectionEnabled && (
                    <Section styles={style.pinToggleSection}>
                        <ToggleItem
                            title={t('profiles.pinProtection.label')}
                            description={t('profiles.pinProtection.labelTopic')}
                            testID={UPSERT_PROFILE_TEST_IDS.PIN_PROTECTION_SWITCH}
                            onValueChange={() => onPinProtectedChange(!userProfile?.isPinProtected)}
                            defaultValue={!!userProfile?.isPinProtected}
                        />
                    </Section>
                )}
                <LoadingWrapper
                    isLoading={isLoading}
                    shouldBeVisible={editMode}
                    wrapperStyle={style.actionButtonContainer}
                >
                    <View>
                        {shouldDisplayDeleteButton && (
                            <TertiaryButton
                                title={t('profiles.delete.deleteButton')}
                                onPress={() => setModalVisible(!isModalVisible)}
                                additionalContainerStyles={style.deleteButton}
                                additionalTextStyles={style.deleteButtonText}
                                disabled={
                                    (userProfile?.name && userProfile?.name?.length < 1) || false
                                }
                                testID={UPSERT_PROFILE_TEST_IDS.EDIT_PROFILE_DELETE_BUTTON}
                            />
                        )}
                        <ActionButton
                            title={t(primaryButtonText).toUpperCase()}
                            onPress={onUpdateProfile}
                            additionalContainerStyles={style.actionButtonStyle}
                            disabled={isButtonDisabled}
                            testID={UPSERT_PROFILE_TEST_IDS.EDIT_PROFILE_SAVE_BUTTON}
                        />
                    </View>
                </LoadingWrapper>
                <LoadingWrapper
                    isLoading={isLoading}
                    shouldBeVisible={mode === UPSERT_PROFILE_MODES.CREATE}
                    wrapperStyle={style.actionButtonContainer}
                >
                    <ActionButton
                        title={t(primaryButtonText).toUpperCase()}
                        onPress={onCreateProfile}
                        additionalContainerStyles={style.actionButtonStyle}
                        disabled={isButtonDisabled}
                        testID={UPSERT_PROFILE_TEST_IDS.NEW_PROFILE_SAVE_BUTTON}
                    />
                </LoadingWrapper>
            </View>
            <DeleteModal
                userProfile={userProfile}
                styles={getDeleteModalStyles}
                isVisible={isModalVisible}
                onModalClose={() => setModalVisible(!isModalVisible)}
                onDeleteProfilePress={onDeleteProfile}
            />
        </SafeAreaView>
    );
};

export default UpsertProfileScreen;
