import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Icon, Interactable, View } from '@24i/nxg-sdk-quarks';
import { Profile } from '@24i/nxg-sdk-photon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionText } from '../SectionText';
import { Label } from './Label';
import getViewingRestrictionsStyles from './styles';
import { ViewingRestrictionStyleGetter } from './styles/types';

interface ViewingRestrictionsProps {
    contentRatings?: number[];
    userProfile: Profile | null;
    styles?: ViewingRestrictionStyleGetter;
    onChange: (rate: number) => void;
}

// Don't even ask :D https://stackoverflow.com/questions/34624100/simulate-display-inline-in-react-native
// You cannot add View elements into text and make them behave like inline block (We need those for margins, paddings and rounded corners)
// The workaround is to add each word to its own element and align the elements
const renderEachWordToItsOwnTextEl = (sentence: string) =>
    sentence.split(' ').map((word, index, originalArray) => (
        <>
            {word !== '6' && word !== '12' && word !== '16' && word !== '18' ? (
                // eslint-disable-next-line react/no-array-index-key
                <SectionText key={index}>
                    {word}
                    {index === originalArray.length - 1 ? '' : ' '}
                </SectionText>
            ) : (
                <Label title={word} active />
            )}
        </>
    ));

const ViewingRestrictions = ({
    styles: getStyles = getViewingRestrictionsStyles,
    userProfile,
    onChange,
    contentRatings = [],
}: ViewingRestrictionsProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation(['sott']);

    const isRatingSelectionVisible = typeof userProfile?.age === 'number';
    const style = getStyles({ theme, isRatingSelectionVisible });

    const Rectangle = ({ rectangleStyles = style.rectangle }) => {
        return <View style={rectangleStyles} />;
    };

    const Circle = ({ circleStyles = style.circle }) => {
        return <View style={circleStyles} />;
    };

    const renderIcon = (rate: number, index: number) => {
        if (userProfile?.age === null || userProfile?.age === undefined) return null;
        if (userProfile?.age === rate) {
            return (
                <View style={style.iconContainer}>
                    <Icon
                        font={theme.icons.settings?.check?.iconFont || ''}
                        name={theme.icons.settings?.check?.iconName || ''}
                        style={style.icon}
                        size={18}
                    />
                </View>
            );
        }
        if (index === 0) {
            return (
                <View>
                    <View style={style.rectangleContainer}>
                        <Rectangle rectangleStyles={style.firstItemRectangle} />
                    </View>
                    <Circle />
                </View>
            );
        }
        if (index === contentRatings.length - 1) {
            return (
                <View>
                    <View style={style.rectangleContainer}>
                        <Rectangle rectangleStyles={style.lastItemRectangle} />
                    </View>
                    <Circle circleStyles={style.circleDisabled} />
                </View>
            );
        }
        if (userProfile.age > rate) {
            return (
                <View>
                    <Rectangle />
                </View>
            );
        }

        if (userProfile.age < rate) {
            return (
                <View>
                    <Rectangle rectangleStyles={style.rectangleDisabled} />
                </View>
            );
        }
        return <View />;
    };

    const ageText =
        userProfile?.age === 0 ? t('profiles.ageRestriction.allAges') : userProfile?.age;
    const restrictionTextEl = (
        <View style={style.restrictionTextWrapper}>
            {renderEachWordToItsOwnTextEl(t('profiles.ageRestriction.on', { ageRating: ageText }))}
        </View>
    );

    const onAllAgesTextEl = <SectionText>{t('profiles.ageRestriction.onAllAges')}</SectionText>;
    const sectionTextElement =
        userProfile?.age === 0 || userProfile?.age === null ? onAllAgesTextEl : restrictionTextEl;

    return isRatingSelectionVisible ? (
        <View style={style.container}>
            <View style={style.descriptionContainer}>{sectionTextElement}</View>
            {contentRatings.map((rate, index) => {
                return (
                    <View key={rate} style={style.ratingContainer}>
                        <View style={style.ratingIconContainer}>{renderIcon(rate, index)}</View>
                        <View style={style.labelContainer}>
                            <Interactable
                                onPress={() => onChange(rate)}
                                testID={`content_rating_${rate}`}
                            >
                                <Label
                                    title={rate === 0 ? t('profiles.ageRestriction.allAges') : rate}
                                    active={userProfile?.age === rate}
                                />
                            </Interactable>
                        </View>
                    </View>
                );
            })}
        </View>
    ) : (
        <View style={style.descriptionContainer}>
            <SectionText>{t('profiles.ageRestriction.off')}</SectionText>
        </View>
    );
};

export default overridable(ViewingRestrictions, 'ViewingRestrictions');
