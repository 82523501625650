import { Platform, Dimensions, PixelRatio } from 'react-native';
import {
    getBrowserName,
    getDeviceId,
    isPlatformAndroid,
    isPlatformAndroidtv,
    isPlatformIos,
    isPlatformTvos,
    isPlatformWeb,
} from '@24i/nxg-core-utils';
import { RuntimeConfig } from '@24i/nxg-core-utils/src/globalSingletons';
import i18n from 'i18next';
import { DeviceInfo as DeviceInformation } from '@24i/nxg-sdk-quantum';
import { DeviceInfo } from './types';
import { ANALYTICS_EVENTS } from './constants';

const getOsVersion = (): string =>
    isPlatformWeb ? getBrowserName().name : (Platform.Version as string);

const getDeviceTimeZone = (): number => {
    const date = new Date();
    return (-1 * date.getTimezoneOffset()) / 60;
};

const getScreenResolution = (): string => {
    const { height, width } = Dimensions.get('window');
    const realHeight = height * PixelRatio.get();
    const realWidth = width * PixelRatio.get();
    return `${realWidth}x${realHeight}`;
};

enum AnalyticsPlatform {
    UNKNOWN = 'unknown',
    IOS = 'iOS',
    TVOS = 'tvOS',
    ANDROID = 'Android',
    ANDROIDTV = 'AndroidTV',
    WINDOWS = 'Windows',
    LINUX = 'Linux',
    MACOS = 'macOS',
    WEB = 'Web',
}

const getPlatform = (): string => {
    if (isPlatformIos) return AnalyticsPlatform.IOS;
    if (isPlatformTvos) return AnalyticsPlatform.TVOS;
    if (isPlatformAndroid) return AnalyticsPlatform.ANDROID;
    if (isPlatformAndroidtv) return AnalyticsPlatform.ANDROIDTV;
    if (isPlatformWeb) {
        const { userAgent } = navigator;
        if (userAgent.includes('iPhone') || userAgent.includes('iPad'))
            return AnalyticsPlatform.IOS;
        if (userAgent.includes('Android')) return AnalyticsPlatform.ANDROID;
        if (userAgent.includes('Windows')) return AnalyticsPlatform.WINDOWS;
        if (userAgent.includes('Linux')) return AnalyticsPlatform.LINUX;
        if (userAgent.includes('Macintosh')) return AnalyticsPlatform.MACOS;
        return AnalyticsPlatform.WEB;
    }
    return AnalyticsPlatform.UNKNOWN;
};

enum AnalyticsDeviceType {
    UNKNOWN = 'unknown',
    MOBILE = 'Mobile',
    TABLET = 'Tablet',
    SMART_TV = 'SmartTV',
    LAPTOP = 'Laptop',
}

const getDeviceType = (): string => {
    if (isPlatformAndroidtv || isPlatformTvos) return AnalyticsDeviceType.SMART_TV;
    if (isPlatformIos || isPlatformAndroid) {
        return DeviceInformation.isTablet()
            ? AnalyticsDeviceType.TABLET
            : AnalyticsDeviceType.MOBILE;
    }
    if (isPlatformWeb) {
        const { userAgent } = navigator;
        if (userAgent.includes('iPhone')) return AnalyticsDeviceType.MOBILE;
        if (userAgent.includes('iPad')) return AnalyticsDeviceType.TABLET;
        if (userAgent.includes('Android') || userAgent.includes('Mobile')) {
            const { height, width } = Dimensions.get('window');
            const isTablet = Math.min(width, height) >= 600;
            return isTablet ? AnalyticsDeviceType.TABLET : AnalyticsDeviceType.MOBILE;
        }
        return AnalyticsDeviceType.LAPTOP;
    }
    return AnalyticsDeviceType.UNKNOWN;
};

export const getDeviceInfo = async (): Promise<DeviceInfo> => ({
    appVersion: RuntimeConfig.get().appVersion,
    serviceId: RuntimeConfig.get().apiService.id,
    osVersion: getOsVersion(),
    platform: getPlatform(),
    deviceId: await getDeviceId(),
    deviceType: getDeviceType(),
    deviceTimeZone: getDeviceTimeZone(),
    language: i18n.language,
    deviceManufacturer: await DeviceInformation.getManufacturer(),
    screenResolution: getScreenResolution(),
});

export const isEventScheduled = (
    redundantEvents: ANALYTICS_EVENTS[],
    eventNameFromPayload: string
): boolean => !redundantEvents.includes(eventNameFromPayload as ANALYTICS_EVENTS);
