/*
@PRODUCT_FEATURE: C 1.2, C 1.2.1
*/
/* eslint-disable react/display-name */
import React from 'react';
import View, { getSignInScreenStyles } from './View';
import { useViewModel } from './viewModel';
import * as prerenderFunctions from '../../pages/signin';
import { ViewModelProps, ViewModelReturnType } from './viewModel/types';

// Using ComponentProps helper because the props from the View component are dynamically adjusted by the HOCs, this is an easy way to extract the final Props without explicitly defining them
type FinalViewProps = React.ComponentProps<typeof View>;

type ManuallyAddedProps =
    | 'onEmailChange'
    | 'onEmailSubmit'
    | 'onPasswordChange'
    | 'onPasswordSubmit'
    | 'onSignInPressTv'
    | 'onForgotPasswordPress';

export type SignInScreenProps = Omit<
    FinalViewProps,
    keyof ViewModelReturnType | ManuallyAddedProps
> &
    ViewModelProps;

export default {
    Main: (props: SignInScreenProps) => {
        const {
            handleEmailChange,
            handleEmailSubmit,
            handlePasswordChange,
            handlePasswordSubmit,
            handleSignInPressTv,
            handleForgotPasswordPress,
            redirectSigninUrl,
            ...viewModelProps
        } = useViewModel();

        return (
            <View
                {...viewModelProps}
                onEmailChange={handleEmailChange}
                onEmailSubmit={handleEmailSubmit}
                onPasswordChange={handlePasswordChange}
                onPasswordSubmit={handlePasswordSubmit}
                onSignInPressTv={handleSignInPressTv}
                onForgotPasswordPress={handleForgotPasswordPress}
                redirectSigninUrl={redirectSigninUrl}
                {...props}
            />
        );
    },
    View,
    useViewModel,
    page: {
        signin: prerenderFunctions,
    },
    // components
    getDefaultStyles: getSignInScreenStyles,
};
