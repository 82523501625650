import { getFont } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import type { StylesDictionary } from 'react-native-render-html';

import { HtmlFontStyleGetter, HtmlStylesGetter } from '../types';

export const getHtmlFontStyles: HtmlFontStyleGetter = (theme, variant, dimensions) => {
    /** WORKAROUND **
     * This function will imitate the behaviour of media queries. Our component HTML from react-native-render-html
     * doesn't support media queries from react-native-media-query, since it doesnt support styles created by Stylesheet api
     * and its views don't contain the data-media/dataSet prop. */
    const styles = getFont(theme, variant);

    if (isPlatformWeb) {
        if (dimensions.width < 801) return styles['@media (max-width: 800px)'];
        if (dimensions.width >= 801 && dimensions.width < 1200)
            return styles['@media (min-width: 801px) and (max-width: 1200px)'];

        delete styles['@media (max-width: 800px)'];
        delete styles['@media (min-width: 801px) and (max-width: 1200px)'];
    }

    return styles;
};

const getHtmlStyles: HtmlStylesGetter = (theme, dimensions) => {
    const htmlTextColor = `${theme.color.textPrimary}99`;

    const baseStyle = {
        ...getHtmlFontStyles(theme, 'body1', dimensions),
        color: htmlTextColor,
    } as StylesDictionary;

    const tagsStyles = {
        h1: {
            ...getHtmlFontStyles(theme, 'h1', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        h2: {
            ...getHtmlFontStyles(theme, 'h2', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        h3: {
            ...getHtmlFontStyles(theme, 'h3', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        h4: {
            ...getHtmlFontStyles(theme, 'h4', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        h5: {
            ...getHtmlFontStyles(theme, 'h5', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        h6: {
            ...getHtmlFontStyles(theme, 'h6', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        p: {
            ...getHtmlFontStyles(theme, 'body1', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        li: {
            ...getHtmlFontStyles(theme, 'body1', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        ol: {
            ...getHtmlFontStyles(theme, 'body1', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        ul: {
            ...getHtmlFontStyles(theme, 'body1', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
        a: {
            ...getHtmlFontStyles(theme, 'h5', dimensions),
            color: htmlTextColor,
        } as StylesDictionary,
    };

    return {
        baseStyle,
        tagsStyles,
    };
};

export default getHtmlStyles;
