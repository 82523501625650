import { SidebarStyles } from '../types';

const getStyles = (theme: any, isSTV: boolean): SidebarStyles => ({
    list: {
        position: isSTV ? 'absolute' : 'fixed',
        overflow: 'hidden',
        zIndex: 25,
    },
    channelCellContainer: {
        ...theme.sidebar.container,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        ...theme.sidebar.text,
    },
    logo: {
        ...theme.sidebar.logo,
        objectFit: 'contain',
        maxHeight: theme.cell.height,
    },
});

export default getStyles;
