import React, { FC } from 'react';
import { usePlayerTimes } from '@24i/player-utils-react';
import {
    PlayPauseButton,
    SeekRelativeButton,
    Sign,
    Timeline,
    PrevNextEpisodeButton,
} from '@24i/player-ui-react';
import { PlayerBase } from '@24i/player-base';
import { MiniPlayerControlsContainer } from './MiniPlayerControlsContainer';
import { MiniPlayerProgressSliderContainer } from './MiniPlayerProgressSliderContainer';
import { formatPlayerTime } from '../../../utils';
import { AdjacentEpisodes, OnPrevNextPress } from '../../../types';

type Props = {
    playerInstance: PlayerBase;
    onPrevNextPress: OnPrevNextPress;
    adjacentEpisodes: AdjacentEpisodes;
};

export const Controls: FC<Props> = ({ playerInstance, onPrevNextPress, adjacentEpisodes }) => {
    const { elapsedTime, durationTime } = usePlayerTimes(playerInstance);
    const elapsedTimeString = formatPlayerTime(elapsedTime);
    const durationTimeString = formatPlayerTime(durationTime);

    return (
        <>
            <MiniPlayerControlsContainer>
                <PrevNextEpisodeButton
                    direction="prev"
                    onPress={onPrevNextPress}
                    disabled={adjacentEpisodes.previousEpisode == null}
                    playerInstance={playerInstance}
                />

                <SeekRelativeButton playerInstance={playerInstance} sign={Sign.Negative} />
                <PlayPauseButton
                    playerInstance={playerInstance}
                    styles={{ icon: { width: 40, height: 40 } }}
                />
                <SeekRelativeButton playerInstance={playerInstance} sign={Sign.Positive} />
                <PrevNextEpisodeButton
                    direction="next"
                    onPress={onPrevNextPress}
                    disabled={adjacentEpisodes.nextEpisode == null}
                    playerInstance={playerInstance}
                />
            </MiniPlayerControlsContainer>

            <MiniPlayerProgressSliderContainer
                leftLabel={elapsedTimeString}
                rightLabel={durationTimeString}
            >
                <Timeline playerInstance={playerInstance} variant="podcast" />
            </MiniPlayerProgressSliderContainer>
        </>
    );
};
