/* eslint-disable @typescript-eslint/no-var-requires */
import { registerIconFont } from '@24i/nxg-sdk-quarks';
import FontAwesome6 from '../../../public/fonts/FontAwesome6_Brands.json';
/* eslint-disable global-require */
const iconFontsMap = {
    fontAwesome: {
        component: require('react-native-vector-icons/dist/FontAwesome').default,
        ttf: require('react-native-vector-icons/Fonts/FontAwesome.ttf'),
        fontFamily: 'FontAwesome',
    },
    fontAwesomeBrands: {
        component: require('react-native-vector-icons').createIconSet(
            FontAwesome6,
            'FontAwesome6_Brands',
            'FontAwesome6_Brands.ttf'
        ),
        ttf: require('../../../public/fonts/FontAwesome6_Brands.ttf'),
        fontFamily: 'FontAwesomeBrands',
    },
    feather: {
        component: require('react-native-vector-icons/dist/Feather').default,
        ttf: require('react-native-vector-icons/Fonts/Feather.ttf'),
        fontFamily: 'Feather',
    },
    entypo: {
        component: require('react-native-vector-icons/dist/Entypo').default,
        ttf: require('react-native-vector-icons/Fonts/Entypo.ttf'),
        fontFamily: 'Entypo',
    },
    // evilIcons: {
    //     component: require('react-native-vector-icons/dist/EvilIcons').default,
    //     ttf: require('react-native-vector-icons/Fonts/EvilIcons.ttf'),
    //     fontFamily: 'EvilIcons',
    // },
    // foundation: {
    //     component: require('react-native-vector-icons/dist/Foundation').default,
    //     ttf: require('react-native-vector-icons/Fonts/Foundation.ttf'),
    //     fontFamily: 'Foundation',
    // },
    ionicons: {
        component: require('react-native-vector-icons/dist/Ionicons').default,
        ttf: require('react-native-vector-icons/Fonts/Ionicons.ttf'),
        fontFamily: 'Ionicons',
    },
    materialCommunityIcons: {
        component: require('react-native-vector-icons/dist/MaterialCommunityIcons').default,
        ttf: require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
        fontFamily: 'MaterialCommunityIcons',
    },
    materialIcons: {
        component: require('react-native-vector-icons/dist/MaterialIcons').default,
        ttf: require('react-native-vector-icons/Fonts/MaterialIcons.ttf'),
        fontFamily: 'MaterialIcons',
    },
    // octicons: {
    //     component: require('react-native-vector-icons/dist/Octicons').default,
    //     ttf: require('react-native-vector-icons/Fonts/Octicons.ttf'),
    //     fontFamily: 'Octicons',
    // },
    // simpleLineIcons: {
    //     component: require('react-native-vector-icons/dist/SimpleLineIcons').default,
    //     ttf: require('react-native-vector-icons/Fonts/SimpleLineIcons.ttf'),
    //     fontFamily: 'SimpleLineIcons',
    // },
    // zocial: {
    //     component: require('react-native-vector-icons/dist/Zocial').default,
    //     ttf: require('react-native-vector-icons/Fonts/Zocial.ttf'),
    //     fontFamily: 'Zocial',
    // },
    antDesign: {
        component: require('react-native-vector-icons/dist/AntDesign').default,
        ttf: require('react-native-vector-icons/Fonts/AntDesign.ttf'),
        fontFamily: 'AntDesign',
    },
};

const registerIconFonts = () => {
    Object.keys(iconFontsMap).forEach((fontName) => {
        const { component, ttf, fontFamily } = iconFontsMap[fontName];
        registerIconFont(fontName, component, ttf, fontFamily);
    });
};

/* eslint-enable global-require */

export default registerIconFonts;
