import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle } from 'react-native';
import { breakpoints } from '../../../../constants';
import { FontWeight, ViewStyleWithExtraProps } from '../../../../types';

type EpisodeDetailsStyles = {
    container: ViewStyleWithExtraProps;
    imageHolder: ViewStyle;
    image: ViewStyle;
    content: ViewStyle;
    date: TextStyle;
    title: TextStyle;
    podcastName: TextStyle;
};

export const getStyles = (theme: Theme): EpisodeDetailsStyles => {
    const borderRadiusFromTheme = theme.elements['generalTile-cornerRadius']?.value;
    const borderRadius = borderRadiusFromTheme ? +borderRadiusFromTheme : 8;
    const h5Props = {
        fontFamily: theme.fonts.L?.h5?.family,
        fontWeight: theme.fonts.L?.h5?.weight as FontWeight,
        fontSize: theme.fonts.L?.h5?.size,
        lineHeight: theme.fonts.L?.h5?.line,
    };

    return {
        container: {
            flexDirection: 'row',
            columnGap: 16,
            alignItems: 'center',
            maxWidth: '100%',
        },

        imageHolder: {
            width: 89,
            height: 50,
            backgroundColor: theme.color.darker1,
            borderRadius,
            shadowColor: '#0d0d10',
            shadowRadius: 6,
            shadowOffset: {
                width: 0,
                height: 4,
            },
            [`@media (max-width: ${breakpoints.md})`]: {
                display: 'none',
            },
        },

        image: {
            width: '100%',
            height: '100%',
            borderRadius,
        },

        content: {
            flexShrink: 1,
        },

        date: {
            color: 'inherit',
            ...h5Props,
            letterSpacing: -0.16,
            opacity: 0.6,
            marginBottom: 1,
        },

        title: {
            color: 'inherit',
            fontFamily: theme.fonts.L?.h4?.family,
            fontWeight: theme.fonts.L?.h4?.weight as FontWeight,
            fontSize: theme.fonts.L?.h4?.size,
            lineHeight: theme.fonts.L?.h4?.line,
            letterSpacing: -0.19,
            marginBottom: 2,
        },
        podcastName: {
            color: 'inherit',
            ...h5Props,
            letterSpacing: -0.16,
            opacity: 0.8,
        },
    };
};
