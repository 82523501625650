import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { isSubscription, Purchase, Rent, Subscription } from '@24i/nxg-sdk-photon/src';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useInvalidatePurchases } from '../../../hooks/query/purchases/useIsAssetPurchasedQuery';
import PurchaseSelection from '../PurchaseSelection';
import ConfirmPurchase from '../ConfirmPurchase';
import {
    PurchaseFlowStep,
    StatusStepData,
    StepData,
    Section,
    SectionsData,
    AvailableOptions,
    PurchaseModalLayout,
} from './types';
import { PurchasesModalProps } from '../types';
import useAvailableAssetPurchasesQuery from '../../../hooks/query/purchases/useAvailableAssetPurchasesQuery';
import { PurchaseError, PurchaseErrorType } from '../ConfirmPurchase/types';

export const isStepStatusStep = (step: StepData): step is StatusStepData =>
    step.layout === PurchaseModalLayout.Status;

const useViewModel = (props: PurchasesModalProps) => {
    const { isVisible, closeModal, asset } = props;
    const { t } = useTranslation();
    const { invalidatePurchasesQuery } = useInvalidatePurchases();
    const { sessionId } = useSessionId();
    const [step, setStep] = useState<PurchaseFlowStep>(PurchaseFlowStep.Selection);
    const [selectedPurchaseItemId, setSelectedPurchaseItemId] = useState<string | undefined>();
    const [isPinValidationOpen, setPinValidationOpen] = useState(false);

    const { data: purchasesItems, isLoading: isFetchingItems } = useAvailableAssetPurchasesQuery(
        { id: asset?.id || '', type: asset?.type || null },
        {
            enabled: Boolean(asset?.id),
        }
    );

    const selectedItem = useMemo(
        () =>
            purchasesItems?.availablePurchases.find(
                (item) =>
                    item.id === selectedPurchaseItemId ||
                    item?.externalAccessValue === selectedPurchaseItemId
            ),
        [purchasesItems?.availablePurchases, selectedPurchaseItemId]
    );

    const inAppPurchasesFeature = useFeature('inAppPurchases');
    const isEnabled = inAppPurchasesFeature?.enabled;
    const disabledPlatforms = inAppPurchasesFeature?.disabledPlatforms;

    const isMyPlatformDisbaled = disabledPlatforms?.includes(Platform.OS as any);
    const showUnavailableSection = !isEnabled || isMyPlatformDisbaled;

    useEffect(() => {
        if (isVisible) {
            const newStep = showUnavailableSection
                ? PurchaseFlowStep.Unavailable
                : PurchaseFlowStep.Selection;

            setStep(newStep);
        }
    }, [isVisible]);

    const onPurchaseSuccess = () => {
        onAnalytics(ANALYTICS_TRIGGERS.PURCHASE_COMPLETE, {
            event: ANALYTICS_EVENTS.PURCHASE_COMPLETE,
        });
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.purchase.step3'),
            sessionId,
        });
        invalidatePurchasesQuery();
        closeModal();
    };

    const handleOnPurchaseSelect = (itemId: string) => {
        setSelectedPurchaseItemId(itemId);
        setStep(PurchaseFlowStep.Confirm);
    };

    const mapStepToPreviousStep: Record<PurchaseFlowStep, PurchaseFlowStep | null> = {
        [PurchaseFlowStep.Selection]: null,
        [PurchaseFlowStep.Confirm]: PurchaseFlowStep.Selection,
        [PurchaseFlowStep.Success]: null,
        [PurchaseFlowStep.Failure]: null,
        [PurchaseFlowStep.Unavailable]: null,
    };

    const previousStep = mapStepToPreviousStep[step];
    const handleOnBackPress = () => {
        if (previousStep !== null) setStep(previousStep);
    };

    const getSections = (availablePurchases: AvailableOptions[]) => {
        const result: SectionsData = [];

        const purchaseSection: Section<Purchase> = { title: '', key: '0', data: [] };
        const rentSection: Section<Rent> = { title: '', key: '1', data: [] };
        const subscriptionSection: Section<Subscription> = { title: '', key: '2', data: [] };

        availablePurchases.forEach((item: AvailableOptions) => {
            switch (item.type) {
                case 'purchase':
                    purchaseSection.data.push(item as Purchase);
                    break;
                case 'rental':
                    rentSection.data.push(item as Rent);
                    break;
                case 'subscription':
                    if (!(item as Subscription).isHidden)
                        subscriptionSection.data.push(item as Subscription);
                    break;
                default:
                    break;
            }
        });

        if (purchaseSection.data.length) result.push(purchaseSection);
        if (rentSection.data.length) result.push(rentSection);
        if (subscriptionSection.data.length) result.push(subscriptionSection);

        return result;
    };

    const getConfirmProductTitle = (): string => {
        if (isPinValidationOpen) return t('pin.enter.purchase.title');
        return isSubscription(selectedItem)
            ? t('svod.add.confirmSubscription.title')
            : t('vod.add.addConfirm.title');
    };

    const sections = useMemo(() => {
        if (purchasesItems) return getSections(purchasesItems.availablePurchases);
        return [];
    }, [purchasesItems]);

    const stepsData: Record<PurchaseFlowStep, StepData> = {
        [PurchaseFlowStep.Selection]: {
            title: t('vod.add.title'),
            subTitle: t('vod.add.description', { assetTitle: asset?.title }) ?? '',
            content: (
                <PurchaseSelection
                    sections={sections}
                    onPurchaseSelect={handleOnPurchaseSelect}
                    isLoading={isFetchingItems}
                />
            ),

            step: PurchaseFlowStep.Selection,
            layout: PurchaseModalLayout.Basic,
        },
        [PurchaseFlowStep.Confirm]: {
            title: getConfirmProductTitle(),
            content: selectedItem ? (
                <ConfirmPurchase
                    selectedItem={selectedItem}
                    assetId={asset?.id}
                    assetType={asset?.type}
                    onPurchaseSuccess={() => setStep(PurchaseFlowStep.Success)}
                    onPurchaseError={(err?: PurchaseError | unknown) => {
                        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
                            screen: t('analytics.documentTitle.purchase.paymentFailure'),
                            sessionId,
                        });
                        if (err instanceof PurchaseError) {
                            if (err.type === PurchaseErrorType.UNAVAILABLE) {
                                setStep(PurchaseFlowStep.Unavailable);
                                return;
                            }
                        }
                        setStep(PurchaseFlowStep.Failure);
                    }}
                    closeModal={closeModal}
                    onPinValidationVisible={setPinValidationOpen}
                    isModalContent
                />
            ) : null,
            step: PurchaseFlowStep.Confirm,
            layout: PurchaseModalLayout.Basic,
        },
        [PurchaseFlowStep.Success]: {
            title: t('tvod.add.purchaseSuccess.title'),
            content: t('tvod.add.purchaseSuccess.description'),
            layout: PurchaseModalLayout.Status,
            actionButton: {
                title: t('common.continue'),
                onPress: onPurchaseSuccess,
            },
            step: PurchaseFlowStep.Success,
        },
        [PurchaseFlowStep.Failure]: {
            title: t('error.H00.title'),
            content: t('error.H00.body'),
            layout: PurchaseModalLayout.Status,
            actionButton: {
                title: t('vod.add.tryAgainButton'),
                onPress: () => setStep(PurchaseFlowStep.Selection),
            },
            cancelButton: {
                onPress: closeModal,
            },
            step: PurchaseFlowStep.Failure,
        },
        [PurchaseFlowStep.Unavailable]: {
            title: t('error.H01.title'),
            content: t('error.H01.body'),
            layout: PurchaseModalLayout.Status,
            actionButton: {
                title: t('common.continue'),
                onPress: closeModal,
            },
            step: PurchaseFlowStep.Unavailable,
        },
    };

    const isBackButtonVisible = previousStep !== null && !isPinValidationOpen;

    return {
        ...props,
        currentStepData: stepsData[step],
        isBackButtonVisible,
        handleOnBackPress,
    };
};

export { useViewModel };
