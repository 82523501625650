import _slicedToArray from"@babel/runtime/helpers/slicedToArray";import _extends from"@babel/runtime/helpers/extends";var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/cwc/app.js";
import React,{useEffect,useState}from'react';

import Application from'@24i/nxg-sdk-smartott/src/Application';
import{FirebaseProvider}from'@24i/nxg-sdk-smartott/src/context/Firebase';
import{ApplicationStoreProvider}from'@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import{useAppStart}from'@24i/nxg-sdk-smartott/src/context/AppStartContext';
import Footer from'@24i/nxg-sdk-smartott/src/components/Footer';

import{DefaultDataProviders}from'@24i/nxg-sdk-smartott-shared/src/context/DefaultDataProviders';
import{QueryProvider}from'@24i/nxg-sdk-smartott-shared/src/context/Query';
import{DefaultSDKProviders}from'@24i/nxg-sdk-smartott/src/context/DefaultSDKProviders';
import{useStorageLanguageSync}from'@24i/nxg-sdk-smartott/src/hooks/cleeng/useStorageLanguageSync';
import{DefaultClientStubsProvider}from'@24i/nxg-sdk-smartott/src/context/DefaultClientStubsProvider';
import{ActivityIndicator}from'@24i/nxg-sdk-quarks';
import defaultTheme from"../../platformAssets/runtime/defaultTheme.json";
import config,{getClients}from"./config";

var runtimeConfig=config.runtimeConfig,staticSplashConfig=config.staticSplashConfig;

var AppModule=function AppModule(props){
var _useAppStart=useAppStart(),initApplication=_useAppStart.initApplication;

var _props$footerLinks=props.footerLinks,footerLinks=_props$footerLinks===void 0?[]:_props$footerLinks,_props$footerIcons=props.footerIcons,footerIcons=_props$footerIcons===void 0?[]:_props$footerIcons;

useEffect(function(){
initApplication();
},[]);

return(
React.createElement(Application.Main,_extends({},
props,{
staticSplashConfig:staticSplashConfig,
renderFooter:function renderFooter(){return React.createElement(Footer,{links:footerLinks,icons:footerIcons,__self:_this,__source:{fileName:_jsxFileName,lineNumber:34,columnNumber:33}});},__self:_this,__source:{fileName:_jsxFileName,lineNumber:31,columnNumber:9}})
));

};

var App=function App(props){
var _useState=useState(),_useState2=_slicedToArray(_useState,2),clients=_useState2[0],setClients=_useState2[1];
useEffect(function(){
setClients(getClients());
},[]);


useStorageLanguageSync();

if(clients){
return(
React.createElement(QueryProvider,{__self:_this,__source:{fileName:_jsxFileName,lineNumber:50,columnNumber:13}},
React.createElement(ApplicationStoreProvider,{runtimeConfig:runtimeConfig,__self:_this,__source:{fileName:_jsxFileName,lineNumber:51,columnNumber:17}},
React.createElement(DefaultDataProviders,{clients:clients,options:{defaultTheme:defaultTheme},__self:_this,__source:{fileName:_jsxFileName,lineNumber:52,columnNumber:21}},
React.createElement(DefaultSDKProviders,{clients:clients,__self:_this,__source:{fileName:_jsxFileName,lineNumber:53,columnNumber:25}},
React.createElement(DefaultClientStubsProvider,{clients:clients,__self:_this,__source:{fileName:_jsxFileName,lineNumber:54,columnNumber:29}},
React.createElement(FirebaseProvider,{__self:_this,__source:{fileName:_jsxFileName,lineNumber:55,columnNumber:33}},
React.createElement(AppModule,_extends({},props,{clients:clients,__self:_this,__source:{fileName:_jsxFileName,lineNumber:56,columnNumber:37}}))
)
)
)
)
)
));

}

return React.createElement(ActivityIndicator,{__self:_this,__source:{fileName:_jsxFileName,lineNumber:66,columnNumber:12}});
};

export default App;