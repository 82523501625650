import { useEffect, useRef } from 'react';
import { IAd, IAdTrackingEvent, PlayerBase } from '@24i/player-base';
import { PlaybackViewProps } from '@24i/nxg-sdk-smartott/src/screens/PlaybackScreen/types';
import { ViewModelReturnType } from '@24i/nxg-sdk-smartott-tv/src/screens/PlaybackScreen/types';
import { isPlatformWeb } from '@24i/nxg-core-utils';
import { useTriggerOnBufferingEnd } from '../useTriggerOnBufferingEnd';
import { useTriggerOnBufferingStart } from '../useTriggerOnBufferingStart';
import { useTriggerOnPlaybackHeartbeat } from '../useTriggerOnPlaybackHeartbeat';
import { useTriggerOnPlaybackMilestone } from '../useTriggerOnPlaybackMilestone';
import { useTriggerOnPlaybackPause } from '../useTriggerOnPlaybackPause';
import { useTriggerOnPlaybackStart } from '../useTriggerOnPlaybackStart';
import { useTriggerOnPlaybackEnded } from '../useTriggerOnPlaybackEnded';
import { useTriggerOnPlaybackStop } from '../useTriggerOnPlaybackStop';
import { useTriggerOnAdLoaded } from '../useTriggerOnAdLoaded';
import { CommonPlayerEventTriggerParams } from '../useCommonPlayerEventParams';
import { useTriggerOnAdSkipped } from '../useTriggerOnAdSkipped';

type PlaybackProps = PlaybackViewProps | ViewModelReturnType;

export const usePlayerAnalytics = (props: PlaybackProps) => {
    const { asset, source, seriesData } = props;
    const playerInstance = useRef<PlayerBase>();
    const currentTime = useRef<number>(0);
    const percentOfPlayback = useRef<number>(0);
    const duration = useRef<number>((asset?.duration || 0) * 1000);
    const commonParams = {
        asset,
        source,
        currentTime: currentTime.current,
        duration: duration.current,
        seriesData,
    };
    const triggerOnStart = useTriggerOnPlaybackStart(commonParams);
    const triggerOnPause = useTriggerOnPlaybackPause(commonParams);
    const triggerOnPlaybackHeartbeat = useTriggerOnPlaybackHeartbeat(commonParams);
    const triggerOnMilestone = useTriggerOnPlaybackMilestone(commonParams);
    const triggerOnBufferingStart = useTriggerOnBufferingStart(commonParams);
    const triggerOnBufferingEnd = useTriggerOnBufferingEnd(commonParams);
    const triggerOnPlaybackEnded = useTriggerOnPlaybackEnded(commonParams);
    const triggerOnPlaybackStop = useTriggerOnPlaybackStop(commonParams);
    const triggerOnAdLoaded = useTriggerOnAdLoaded(commonParams);
    const triggerOnAdSkipped = useTriggerOnAdSkipped(commonParams);

    const setPlayerCommonTriggerParams = () => {
        if (playerInstance.current) {
            currentTime.current = playerInstance.current.currentTime;
            const newDuration = playerInstance.current?.duration;
            if (Number.isFinite(newDuration)) duration.current = newDuration;
        }
        if (duration.current) {
            percentOfPlayback.current = (currentTime.current / duration.current) * 100;
        }
    };

    const getPlayerCommonTriggerParams: () => CommonPlayerEventTriggerParams = () => ({
        percentOfPlayback: percentOfPlayback.current,
        currentPlaybackTimeMs: currentTime.current,
        duration: duration.current,
    });

    const onLoadStart = () => {
        duration.current = (asset?.duration || 0) * 1000;
    };

    const onTimeUpdate = () => {
        setPlayerCommonTriggerParams();
        triggerOnPlaybackHeartbeat(getPlayerCommonTriggerParams());
        triggerOnMilestone(getPlayerCommonTriggerParams());
    };

    const onPlay = () => {
        props.onPlay?.();
        setPlayerCommonTriggerParams();
        triggerOnStart(getPlayerCommonTriggerParams());
    };

    const onPause = () => {
        props.onPause?.();
        setPlayerCommonTriggerParams();
        triggerOnPause(getPlayerCommonTriggerParams());
    };

    const onEnd = () => {
        props.onEnd?.();
        triggerOnPlaybackEnded(getPlayerCommonTriggerParams());
    };

    const onStop = () => {
        props.onStop?.();
        triggerOnPlaybackStop(getPlayerCommonTriggerParams());
    };

    const onBufferingStart = () => {
        triggerOnBufferingStart(getPlayerCommonTriggerParams());
    };

    const onBufferingEnd = () => {
        triggerOnBufferingEnd(getPlayerCommonTriggerParams());
    };

    const onAdStarted = (ad: IAd) => {
        const { title, adBreakIndex } = ad;
        triggerOnAdLoaded({ adName: title, adPosition: adBreakIndex });
    };

    const onAdSkipped = (adTrackingEvent: IAdTrackingEvent) => {
        const { ad, trackingEvent } = adTrackingEvent;
        if (trackingEvent === 'skip') {
            triggerOnAdSkipped({ adName: ad?.title, adPosition: ad?.adBreakIndex });
        }
    };

    const addListeners = (): void => {
        playerInstance.current?.addEventListener('timeupdate', onTimeUpdate);
        playerInstance.current?.addEventListener('waiting', onBufferingStart);
        playerInstance.current?.addEventListener('canplay', onBufferingEnd);
        playerInstance.current?.addEventListener('adstarted', onAdStarted);
        playerInstance.current?.addEventListener('adtracking', onAdSkipped);
        playerInstance.current?.addEventListener('loadstart', onLoadStart);
    };

    const setVideoPlayerRef: PlaybackProps['setVideoPlayerRef'] = (ref) => {
        if (!playerInstance.current) {
            playerInstance.current = ref;
            if (isPlatformWeb) addListeners();
        }
        return props.setVideoPlayerRef?.(ref);
    };

    useEffect(() => {
        if (!isPlatformWeb) addListeners();
        return () => {
            playerInstance.current?.removeEventListener('timeupdate', onTimeUpdate);
            playerInstance.current?.removeEventListener('waiting', onBufferingStart);
            playerInstance.current?.removeEventListener('canplay', onBufferingEnd);
            playerInstance.current?.removeEventListener('adstarted', onAdStarted);
            playerInstance.current?.removeEventListener('adtracking', onAdSkipped);
            playerInstance.current?.removeEventListener('loadstart', onLoadStart);
        };
    }, [playerInstance.current]);

    return { onPlay, onPause, onEnd, onStop, setVideoPlayerRef };
};
