import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import {
    Asset,
    ASSET_TYPE,
    Broadcast,
    PageSection,
    PRODUCT_SCREENS,
} from '@24i/nxg-sdk-photon/src';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../navigation/constants';
import { DynaRowScreenProps, DynaRowViewProps, PackshotPressType } from '../types';
import useShared from './shared';
import { useCurrentWebPageConfig } from '../../../hooks/useCurrentWebPageConfig';
import { useOpenExternalURL } from '../../../navigation/utils';

const useViewModel = (props: DynaRowScreenProps): DynaRowViewProps => {
    const {
        goToDetails: customGoToDetails,
        onLinkPress: customOnLinkPress,
        pageId: pageIdFromProps,
    } = props;

    const router = useRouter();
    const { currentPageConfig } = useCurrentWebPageConfig();
    const pageId = pageIdFromProps || currentPageConfig?.reference || undefined;
    const { openExternalURL } = useOpenExternalURL();

    const pageName = currentPageConfig?.name ?? '';
    const pageTitle = currentPageConfig?.title ?? '';
    const hasScreenType = !!Object.values(PRODUCT_SCREENS).find(
        (screen) => screen === currentPageConfig?.screenTypeToUse
    );

    const { sections, isLoading, hasError, handleGoToPlayer, onPackshotPress } = useShared({
        ...props,
        pageId,
        hasScreenType,
    });

    const handleLinkPress = (item: Asset) => {
        if (customOnLinkPress) {
            customOnLinkPress(item);
            return;
        }

        const { target } = item;
        let href;
        let as;

        if (target?.type === 'external') {
            if (target?.link) {
                openExternalURL(target.link);
            }

            return;
        }

        if (target?.type === 'epg') {
            const epgLink = SOTT_DEFAULT_WEB_SCREENS.TvGuide.getLink();
            href = epgLink.href;
            as = epgLink.as;
        }

        if (target?.type === 'textpage') {
            const textPageLink = SOTT_DEFAULT_WEB_SCREENS.TextPage.getLink({
                id: item?.target?.link || '',
            });
            href = textPageLink.href;
            as = textPageLink.as;
        }

        if (target?.type === 'page') {
            const rowLink = SOTT_DEFAULT_WEB_SCREENS.Page.getLink({
                id: item?.target?.link || '',
            });
            href = rowLink.href;
            as = rowLink.as;
        }

        if (!href || !as) return;

        router.push(href, as);
    };

    const handleGoToDetails = (item: Asset & { broadcastMetadata?: Broadcast }) => {
        if (customGoToDetails) {
            customGoToDetails(item);
            return;
        }

        if (!item.id) return;

        const detailsLink = SOTT_DEFAULT_WEB_SCREENS.Details.getLink({
            type: item.type,
            id: item.id,
        });

        const channelId = item.channel?.id;

        const { EPISODE, MOVIE, CHANNEL, SERIES } = ASSET_TYPE;
        let queryParams;

        if (item.type === CHANNEL) {
            queryParams = {
                id: item.broadcastMetadata?.id,
                type: item.broadcastMetadata?.type,
                channelId: item.id,
                sectionLabel: item.sectionLabel,
            };
        } else if (item.type === EPISODE || item.type === MOVIE || item.type === SERIES) {
            queryParams = {
                id: item.id,
                type: item.type,
                sectionLabel: item.sectionLabel,
            };
        } else {
            queryParams = {
                id: item.id,
                type: item.type,
                channelId,
                sectionLabel: item.sectionLabel,
            };
        }

        router.push({
            pathname: detailsLink.href,
            query: {
                ...queryParams,
            },
        });
    };

    const handleSeeAllItemPress = (section: PageSection) => {
        const sectionId = section.id;
        if (sectionId) {
            const link =
                section.computedPlaylist === 'favorite'
                    ? SOTT_DEFAULT_WEB_SCREENS.MyList.getLink()
                    : SOTT_DEFAULT_WEB_SCREENS.SeeAll.getLink({
                          pageId: pageId || '',
                          sectionId,
                      });

            router.push(link.href, link.as);
        }
    };

    const redirectToPodcastDetailPage = (item: Asset) => {
        const detailsLink = SOTT_DEFAULT_WEB_SCREENS.Details.getLink({
            type: ASSET_TYPE.PODCAST_SERIES,
            id: item.series,
        });

        const queryParams = {
            type: ASSET_TYPE.PODCAST_SERIES,
            id: item.series,
            episodeToPlay: item.id,
        };

        router.push({
            pathname: detailsLink.href,
            query: {
                ...queryParams,
            },
        });
    };

    const handlePackshotPress = ({
        section,
        asset,
        onPurchaseAsset,
        playPodcastFromDetailPage,
    }: PackshotPressType) => {
        onPackshotPress({
            section,
            asset,
            onGoToPlayer: () => {
                handleGoToPlayer?.(asset, onPurchaseAsset, playPodcastFromDetailPage);
            },
            onGoToDetails: () => handleGoToDetails?.(asset),
            onLinkPress: () => handleLinkPress?.(asset),
            onPurchaseAsset,
            playPodcastFromDetailPage,
            redirectToPodcastDetailPage,
        });
    };

    return {
        ...props,
        sections,
        isLoading,
        hasError,
        pageId,
        goToDetails: handleGoToDetails,
        handleSeeAllItemPress,
        handlePackshotPress,
        pageName,
        pageTitle,
    };
};

export { useViewModel };
