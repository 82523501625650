import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { Theme, ThemeDataClient } from '@24i/nxg-sdk-photon';
import { Storage } from '@24i/nxg-sdk-quantum';
import _ from 'lodash';
import { BackstageApiBase } from '../../base';
import { replaceEmptyArraysWithEmptyObjects } from '../../utils';
import { CustomIconsResponseGuard } from './customIconsResponseGuard';
import { mapThemeResponse } from './mappers';
import { ThemeResponseItemGuard, ThemeResponseGuard } from './themeResponseGuard';
import { CustomIcons, ThemeResponseItem } from './types';
import backstageDefaultTheme, { DefaultTheme } from './backstageDefaultTheme';

const SERVICE_THEME_ID = 'SERVICE_THEME_ID';

export class BackstageThemeDataClient extends BackstageApiBase implements ThemeDataClient {
    defaultTheme: DefaultTheme;

    constructor(params: BaseApiParams) {
        super(params);
        this.defaultTheme = _.merge(backstageDefaultTheme, params.defaultTheme);
    }

    fetchTheme = async (): Promise<Theme> => {
        const themeId = await Storage.getItem(SERVICE_THEME_ID);
        const themeResponse = themeId
            ? await this.request({
                  path: `/themes/${themeId}`,
                  method: 'GET',
                  guard: ThemeResponseItemGuard,
              })
            : (this.defaultTheme as unknown as ThemeResponseItem);

        const customIcons = await this.request({
            path: '/icons/custom',
            method: 'GET',
            guard: CustomIconsResponseGuard,
        }).then(replaceEmptyArraysWithEmptyObjects<CustomIcons>(['icons']));

        return mapThemeResponse(themeResponse, this.defaultTheme, customIcons);
    };

    fetchAllThemes = async () => {
        const themeResponse = await this.request({
            path: '/themes',
            method: 'GET',
            guard: ThemeResponseGuard,
        });

        const customIcons = await this.request({
            path: '/icons/custom',
            method: 'GET',
            guard: CustomIconsResponseGuard,
        }).then(replaceEmptyArraysWithEmptyObjects<CustomIcons>(['icons']));

        const allThemes = themeResponse.items.map((theme) =>
            mapThemeResponse(theme, this.defaultTheme, customIcons)
        );

        return allThemes;
    };

    selectTheme = (selectedThemeId: string) => Storage.setItem(SERVICE_THEME_ID, selectedThemeId);

    resetSelectedTheme = () => Storage.removeItem(SERVICE_THEME_ID);
}

export const createBackstageThemeDataClient = (params: BaseApiParams) =>
    new BackstageThemeDataClient(params);
