/* eslint-disable max-statements */
import React from 'react';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import ExpandableText from '@24i/nxg-sdk-gluons/src/components/text/ExpandableText';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { useTranslation } from 'react-i18next';
import { ASSET_TYPE, isLiveEvent } from '@24i/nxg-sdk-photon';
import AssetLabel from '../../../../../components/AssetLabel';
import TitleView from '../../../../../components/TitleView';
import { useStore } from '../../../../../context/ApplicationStore';
import { DETAILS_SCREEN_TEST_IDS } from '../../../test-utils';
import getDetailsContentStyles from '../styles';
import RatingModal from '../../../../../components/RatingModal';
import ShareDropdown from '../../../../../components/ShareDropdown';
import { BlockModalTypes } from '../../../../../components/BlockedModal/types';
import { LoadingWrapper } from '../../../../../..';
import {
    iconForRatingState,
    iconForRecordingState,
    titleForRatingState,
    titleForRecordingState,
} from '../../utils';
import { DetailContentViewProps, PrimaryButtonType } from '../types';
import { PageDetailsContentWebStyles } from '../styles/types';
import DetailsProgressBar from '../../DetailsProgressBar';
import useBlockedModal from '../../../../../components/BlockedModal/hooks';
import EpisodeInfo from '../../../../../components/EpisodeInfo';

const DetailsScreenContent = ({
    asset,
    subtitle,
    title,
    primaryButtonType,
    recordingState,
    description,
    gradientParams,
    onPlayPress,
    onRemindPress,
    onReplayPress,
    onMyListPress,
    onRecordPress,
    styles: getStyles = getDetailsContentStyles,
    isAddedToMyList,
    mainButtonsOverride,
    actionButtonTitle,
    actionButtonStyles,
    purchaseButtonTitle,
    openPurchaseModal,
    assetRating,
    fetchingRatingInfo,
    isRatingModalVisible,
    onRatingAction,
    onRemoveRatingAction,
    setRatingModalVisibility,
    shouldDisplayRecordButton,
    shouldDisplayRatingButton,
    shouldDisplayRestartButton,
    shouldDisplayListableButton,
    shouldDisplayShareButton,
    shouldDisplayPrimaryButton,
    shouldDisplayEpisodeInfo,
    hasPlayableAction,
    shouldDisplayReminderButton,
    isShareDropdownVisible,
    setShareDropdownVisibility,
    fetchingButtonData,
    reminderIsSet,
    fetchingReminder,
    episodeToWatch,
    isAssetInProgress,
    isLiveBroadcast,
    onShare,
}: DetailContentViewProps) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme) as PageDetailsContentWebStyles;
    const { userData } = useStore();
    const { openBlockedModal } = useBlockedModal();

    const DefaultPurchaseButton = (props) => (
        <ActionButton
            title={purchaseButtonTitle ?? t('asset.playback.purchaseButton')}
            icon={theme.icons.details.purchase}
            additionalContainerStyles={{
                ...styles.additionalActionButtonContainerStyles,
                ...actionButtonStyles(styles, asset),
            }}
            additionalTextStyles={styles.additionalActionButtonTextStyles}
            onPress={() => {
                if (!userData) {
                    openBlockedModal(BlockModalTypes.ACCESS);
                    return;
                }

                if (asset) openPurchaseModal(asset);
            }}
            gradientParams={gradientParams}
            testID={DETAILS_SCREEN_TEST_IDS.PURCHASE_BUTTON}
            {...props}
        />
    );

    const DefaultPlayButton = (props) => (
        <ActionButton
            title={actionButtonTitle}
            icon={theme.icons.details.play}
            additionalContainerStyles={{
                ...styles.additionalActionButtonContainerStyles,
                ...actionButtonStyles(styles, asset),
            }}
            additionalTextStyles={styles.additionalActionButtonTextStyles}
            onPress={onPlayPress}
            gradientParams={gradientParams}
            testID={DETAILS_SCREEN_TEST_IDS.PLAY_BUTTON}
            {...props}
        />
    );
    const renderDefaultPlayButton = (props = {}) => {
        const onPress = () => {
            onPlayPress();
        };
        return (
            <ActionButton
                title={actionButtonTitle}
                icon={theme.icons.details.play}
                additionalContainerStyles={{
                    ...styles.additionalActionButtonContainerStyles,
                    ...actionButtonStyles(styles, asset),
                }}
                additionalTextStyles={styles.additionalActionButtonTextStyles}
                onPress={onPress}
                gradientParams={gradientParams}
                testID={DETAILS_SCREEN_TEST_IDS.PLAY_BUTTON}
                {...props}
            />
        );
    };

    const DefaultShareButton = (props) => (
        <>
            <TertiaryButton
                testID={DETAILS_SCREEN_TEST_IDS.SHARE_BUTTON}
                title={t('asset.shareButton')}
                icon={theme.icons.details.share}
                iconSize={20}
                additionalContainerStyles={{
                    ...styles.additionalSecondaryButtonContainerStyles,
                    ...styles.tertiaryButtonContainer,
                }}
                additionalTextStyles={styles.tertiaryButtonTextContainer}
                onPress={onShare}
                gradientParams={gradientParams}
                {...props}
            />
            {isShareDropdownVisible && (
                <ShareDropdown closeDropdown={() => setShareDropdownVisibility?.(false)} />
            )}
        </>
    );

    const DefaultRateButton = (props) => (
        <TertiaryButton
            testID={DETAILS_SCREEN_TEST_IDS.RATE_BUTTON}
            iconSize={20}
            additionalContainerStyles={{
                ...styles.additionalSecondaryButtonContainerStyles,
                ...styles.tertiaryButtonContainer,
            }}
            additionalTextContainerStyles={styles.tertiaryButtonTextContainer}
            onPress={() => {
                if (!userData) {
                    openBlockedModal(BlockModalTypes.ACCESS);
                    return;
                }
                setRatingModalVisibility?.(true);
            }}
            title={titleForRatingState(assetRating, t)}
            icon={iconForRatingState(assetRating, theme)}
            {...props}
        />
    );

    const DefaultUnavailableButton = (props) => (
        <ActionButton
            title={t('asset.playback.unavailableButton')}
            icon={theme.icons.details.geoBlocked}
            additionalContainerStyles={{
                ...styles.additionalActionButtonContainerStyles,
                ...actionButtonStyles(styles, asset),
            }}
            additionalTextStyles={styles.additionalActionButtonTextStyles}
            onPress={() =>
                !userData
                    ? openBlockedModal(BlockModalTypes.ACCESS)
                    : openBlockedModal(BlockModalTypes.GEOBLOCK, t('error.E05.body') as string)
            }
            gradientParams={gradientParams}
            testID={DETAILS_SCREEN_TEST_IDS.UNAVAILABLE_BUTTON}
            {...props}
        />
    );

    const DefaultRecordButton = () => (
        <TertiaryButton
            iconSize={20}
            testID={DETAILS_SCREEN_TEST_IDS.RECORD_BUTTON}
            additionalContainerStyles={{
                ...styles.additionalSecondaryButtonContainerStyles,
                ...styles.tertiaryButtonContainer,
            }}
            onPress={() => {
                if (!userData) {
                    openBlockedModal(BlockModalTypes.ACCESS);
                    return;
                }
                onRecordPress(recordingState.status);
            }}
            title={titleForRecordingState(t, recordingState.status)}
            icon={iconForRecordingState(theme, recordingState.status)}
        />
    );

    const DefaultStartOverButton = (props) => (
        <TertiaryButton
            testID={DETAILS_SCREEN_TEST_IDS.START_OVER_BUTTON}
            icon={theme.icons.details.startOver}
            iconSize={28}
            onPress={onReplayPress}
            additionalContainerStyles={{
                ...styles.additionalSecondaryButtonContainerStyles,
                ...styles.addToListButtonContainer,
            }}
            {...props}
        />
    );

    const DefaultRemindMeButton = () => (
        <SubactionButton
            testID={DETAILS_SCREEN_TEST_IDS.REMIND_BUTTON}
            onPress={onRemindPress}
            additionalContainerStyles={styles.remindButtonContainer}
            icon={
                reminderIsSet ? theme.icons.details.reminderIsSet : theme.icons.details.setReminder
            }
            title={
                reminderIsSet ? t('reminders.remove.removeButton') : t('reminders.add.addButton')
            }
        />
    );

    const DefaultAddToListButton = (props) => (
        <TertiaryButton
            testID={DETAILS_SCREEN_TEST_IDS.ADD_TO_FAVORITES_BUTTON}
            icon={
                isAddedToMyList
                    ? theme.icons?.details.removeFromList
                    : theme.icons?.details.addToList
            }
            iconSize={25}
            onPress={onMyListPress}
            additionalContainerStyles={{
                ...styles.additionalSecondaryButtonContainerStyles,
                ...styles.addToListButtonContainer,
            }}
            {...props}
        />
    );

    const renderPrimaryActionButton = () => {
        switch (primaryButtonType) {
            case PrimaryButtonType.UNAVAILABLE:
                return <DefaultUnavailableButton />;
            case PrimaryButtonType.PURCHASE:
                return <DefaultPurchaseButton />;
            case PrimaryButtonType.PLAY:
                return renderDefaultPlayButton();
            default:
                return <></>;
        }
    };

    return (
        <View style={styles.content}>
            <RatingModal
                isVisible={!!isRatingModalVisible}
                title={t('asset.rate.title')}
                description={`${t('asset.rate.description')}'${title}'`}
                onClose={() => setRatingModalVisibility?.(false)}
                onRate={onRatingAction}
                onRemove={onRemoveRatingAction}
                rating={assetRating}
                asset={asset}
            />
            <View style={styles.leftCon}>
                {!!asset.assetLabel && (
                    <View style={styles.assetLabelContainer}>
                        <AssetLabel
                            text={asset.assetLabel}
                            testID={DETAILS_SCREEN_TEST_IDS.VOD_TAG_TEXT}
                        />
                    </View>
                )}
                <TitleView
                    title={title || ''}
                    channelLogo={asset.channelLogo}
                    startsAt={asset?.startsAt}
                    endsAt={asset?.endsAt}
                    subtitle={subtitle}
                    rating={asset.rating ? Number(asset?.rating) : undefined}
                    classifications={asset?.ageClassification}
                    testID={DETAILS_SCREEN_TEST_IDS.VOD_TITLE_TEXT}
                    isLiveEvent={isLiveEvent(asset)}
                    metadataTestID={DETAILS_SCREEN_TEST_IDS.METADATA}
                    displayTimeOnly={isLiveBroadcast}
                >
                    <View
                        style={{
                            ...styles.actionButtonContainer,
                            ...(!hasPlayableAction &&
                                ASSET_TYPE.LIVE_EVENT &&
                                styles.emptyActionButtonContainer),
                        }}
                    >
                        {mainButtonsOverride ? (
                            mainButtonsOverride({ DefaultActionButton: DefaultPlayButton, asset })
                        ) : (
                            <>
                                {shouldDisplayPrimaryButton && (
                                    <LoadingWrapper
                                        isLoading={primaryButtonType === PrimaryButtonType.LOADING}
                                        loaderProps={{
                                            color: theme.color.textPrimary,
                                            size: 'large',
                                            style: styles.loaderWrapper,
                                        }}
                                    >
                                        {renderPrimaryActionButton()}
                                    </LoadingWrapper>
                                )}
                                {isAssetInProgress &&
                                    hasPlayableAction &&
                                    shouldDisplayRestartButton && <DefaultStartOverButton />}
                                {shouldDisplayListableButton && <DefaultAddToListButton />}
                                {shouldDisplayReminderButton && !fetchingReminder && (
                                    <DefaultRemindMeButton />
                                )}
                            </>
                        )}
                    </View>
                </TitleView>
                {isAssetInProgress && (
                    <DetailsProgressBar episodeToWatch={episodeToWatch} asset={asset} />
                )}
                {shouldDisplayEpisodeInfo && <EpisodeInfo asset={asset} />}
                <ExpandableText
                    numberOfLines={4}
                    text={description || ''}
                    testID={DETAILS_SCREEN_TEST_IDS.LONG_DESCRIPTION_TEST}
                />
                <LoadingWrapper
                    shouldBeVisible
                    isLoading={!!fetchingButtonData}
                    wrapperStyle={styles.subtitleView}
                    wrapperTestId="detaiPageViewIn"
                    loaderProps={{ size: 'large', style: styles.tertiaryButtonContainer }}
                >
                    <>
                        <LoadingWrapper
                            shouldBeVisible={shouldDisplayRatingButton}
                            isLoading={!!fetchingRatingInfo}
                            loaderProps={{ size: 'large', style: styles.tertiaryButtonContainer }}
                        >
                            <DefaultRateButton />
                        </LoadingWrapper>
                        <LoadingWrapper
                            shouldBeVisible={shouldDisplayRecordButton}
                            isLoading={!!recordingState.loading}
                            loaderProps={{ size: 'large', style: styles.tertiaryButtonContainer }}
                        >
                            <DefaultRecordButton />
                        </LoadingWrapper>
                        {shouldDisplayShareButton && <DefaultShareButton />}
                    </>
                </LoadingWrapper>
            </View>
        </View>
    );
};

export { getDetailsContentStyles };
export default overridable(DetailsScreenContent, 'DetailsScreenContent');
