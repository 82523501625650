import React, { ReactElement } from 'react';
import { isPlatformWeb } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ASSET_TYPE, PageSection } from '@24i/nxg-sdk-photon/src';
import SearchNothingFound from '../../SearchNothingFound';
import { getWebDynaRowScreenStylesOverride } from '../styles';
import { SearchScreenContentProps } from '../types';
import DynaRowScreen from '../../../../DynaRowScreen';
import { DynaRowScreenModeEnum } from '../../../../DynaRowScreen/types';

const SearchScreenContent = (props: SearchScreenContentProps): ReactElement => {
    const {
        items = [],
        initialData = [],
        query = '',
        overrideInitialDataLayout = null,
        searchEnabled,
        navigation,
        route,
    } = props;

    const renderContent = () => {
        if (overrideInitialDataLayout && query === '') return overrideInitialDataLayout();
        if (
            (searchEnabled && query !== '') ||
            (!overrideInitialDataLayout && query === '' && initialData.length > 0)
        ) {
            const data = query === '' ? initialData : items;
            const shouldGoToPlayer = ASSET_TYPE.EPISODE || ASSET_TYPE.CHANNEL;
            const updatedSections: PageSection[] = [];
            data.forEach((section) =>
                updatedSections.push({
                    actionForAllItems: section.id === shouldGoToPlayer ? 'player' : 'detail',
                    ...section,
                })
            );

            if (data.length > 0)
                return (
                    <DynaRowScreen.Main
                        sections={updatedSections}
                        styles={isPlatformWeb ? getWebDynaRowScreenStylesOverride : undefined}
                        mode={DynaRowScreenModeEnum.BASIC_DISPLAY}
                        navigation={navigation}
                        route={route}
                        hideTitle
                        playPodcastFromDetailPage={isPlatformWeb}
                    />
                );
            return <SearchNothingFound query={query} />;
        }
        return <></>;
    };

    return renderContent();
};

export default overridable(SearchScreenContent, 'SearchScreenContent');
