import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { GridProps } from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid/types';
import { IconButtonProps } from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton/types';
import { Asset, RecordingStatus, Season, Theme, Broadcast } from '@24i/nxg-sdk-photon';
import { EventType } from '@24i/nxg-sdk-gluons/src';
import type { ExtendedViewStyle } from '@24i/nxg-sdk-gluons/src/types';
import { GradientParams } from '@24i/nxg-sdk-quarks/src/types';
import { EpisodePickerProps } from './components/EpisodePicker/types';
import { WithErrorModalProps } from '../../components/GenericModal/hoc/ErrorModal';
import { DetailsTabPropsMobile, DetailsTabPropsWeb } from './components/DetailsTab/types';

export enum DETAILS_ERROR_ENUM {
    DETAILS = 'Details',
    EXTRAS = 'Extras',
    RELATED = 'RelatedAssets',
    SEASONS = 'Seasons',
}

export interface DetailsStyles {
    holder: ViewStyle;
    imageBackground?: ViewStyle;
    imageStyle?: StyleProp<ImageStyle>;
    contentPickerHorizontalLine: ViewStyle;
    contentPickerItemText?: TextStyle;
    bottomPart: ViewStyle;
    bottomPartContainer: ViewStyle;
    actionButtonText?: TextStyle;
    content?: ViewStyle;
    parallax?: {
        height: number;
    };
    // Tv
    gridWrapper?: ViewStyle;
    focusGuideComponentLong?: ViewStyle;
    focusGuideComponentShort?: ViewStyle;
    // Web
    topGradient?: ViewStyle;
    bottomGradient?: ViewStyle;
    wrapperGradient?: ViewStyle;
    contentContainer?: ViewStyle;
    contentPickerContainer?: ViewStyle;
    contentPickerItem?: ViewStyle;
    activeTabLine?: ViewStyle;
    actionButtonContainer?: ViewStyle;
    inactiveTabText?: ExtendedViewStyle;
    loader?: ViewStyle;
}

export interface UseRecordingAssetProps {
    recordingState: RecordingStatus;
    onSetSingleRecordingPress: () => void;
    onRemoveSingleRecordingPress: () => void;
    onSetMultipleRecordingPress: () => void;
    onRemoveMultipleRecordingPress: () => void;
}

export interface UseSharedModelDetails extends UseRecordingAssetProps {
    asset?: Asset | Broadcast;
    seasons?: Season[];
    related?: Asset[];
    recordingState: RecordingStatus;
    isLoading: boolean;
    reminderIsSet: boolean;
    refetchData: () => void;
    onSetSingleRecordingPress: () => void;
    onRemoveSingleRecordingPress: () => void;
    onSetMultipleRecordingPress: () => void;
    onRemoveMultipleRecordingPress: () => void;
    fetchReminder: () => Promise<void>;
    fetchingReminder: boolean;
    setReminderIsSet: Dispatch<SetStateAction<boolean>>;
    isAddedToMyList: boolean;
    onMyListPress?: () => Promise<void>;
    extraItems?: Asset[];
    logReminderError: (error: unknown) => void;
    handleOnPlayPress: ({
        streamAsset,
        isStartOver,
        onPurchaseAsset,
    }: {
        streamAsset: Asset;
        isStartOver?: boolean;
        onPurchaseAsset?: (assetToPurchase: Asset) => void;
    }) => Promise<void>;
    errorForModal: ErrorToDiplay | null;
    handlePurchaseAsset: (asset: Asset, openPurchaseModal) => void;
    episodeToPlay?: string;
}

export interface DetailsScreenProps extends ScreenProps {
    query?: Asset & { episodeToPlay?: string };
    mainButtonsOverride?: ({
        DefaultActionButton,
        DefaultSubactionButton,
        asset,
    }: {
        DefaultActionButton?: (props: IconButtonProps) => JSX.Element;
        DefaultSubactionButton?: (props: IconButtonProps) => JSX.Element;
        asset?: Asset;
    }) => JSX.Element;
    styles?: (theme: Theme) => DetailsStyles;
    renderEpisodePicker?: (props: EpisodePickerProps) => JSX.Element;
    renderPackshotGrid?: (props: GridProps) => JSX.Element;
    renderDetailsTab?: (props: DetailsTabPropsMobile | DetailsTabPropsWeb) => JSX.Element;
    recordError?: (error: string) => void;
}

export interface TabItem {
    label: string;
    content: JSX.Element | null;
    testID: string;
}

export interface ErrorToDiplay {
    title: string;
    message: string;
}

export interface DetailsViewProps extends DetailsScreenProps, UseSharedModelDetails {
    asset?: Asset | Broadcast;
    seasons?: Season[];
    related?: Asset[];
    extraItems?: Asset[];
    recordingState: RecordingStatus;
    isLoading: boolean;
    reminderIsSet: boolean;
    additionalContentPickerItems?: [];
    fadeColors?: string[];
    topGradient?: string[];
    gradientParams?: GradientParams;
    renderTabItems?: (tabs: TabItem[]) => TabItem[];
    onSetSingleRecordingPress: () => void;
    onRemoveSingleRecordingPress: () => void;
    onSetMultipleRecordingPress: () => void;
    onRemoveMultipleRecordingPress: () => void;
    onSharePress?: () => void;
    onRelatedPress?: (item: Asset, callback: () => void, event?: EventType) => void;
    fetchReminder: () => Promise<void>;
    fetchingReminder: boolean;
    setReminderIsSet: Dispatch<SetStateAction<boolean>>;
    isAddedToMyList: boolean;
    onMyListPress?: () => Promise<void>;
    onRemindPress: (asset: Broadcast) => Promise<void>;
    errorForModal: ErrorToDiplay | null;
    onCloseErrorModal?: () => void;
}

export interface UseSharedDetails {
    isAddedToMyList: boolean;
    onMyListPress?(): Promise<void>;
    reminderIsSet: boolean;
    fetchReminder(): Promise<void>;
    fetchingReminder: boolean;
    onSetSingleRecordingPress: () => void;
    onRemoveSingleRecordingPress: () => void;
    onSetMultipleRecordingPress: () => void;
    onRemoveMultipleRecordingPress: () => void;
    onSharePress?: () => void;
    onRelatedPress?: (item: Asset, callback: () => void, event?: MouseEvent) => void;
    recordingState: RecordingStatus;
    loadingDetails: boolean;
    asset?: Asset | null;
    seasons?: Season[];
    related?: Asset[];
}

export type DetailsViewPropsWithError = DetailsViewProps & WithErrorModalProps;
