import { Platform } from 'react-native';

export const isValidDTAP = (environment: string) => {
    const environments = ['debug', 'development', 'staging', 'production'];
    return environments.includes(environment);
};

export const getEnvironment = (sentryEnvironment: string) =>
    isValidDTAP(sentryEnvironment) ? sentryEnvironment : 'debug';

export const getPlatform = () => {
    if (Platform.isTV && Platform.OS === 'ios') return 'tvos';
    return Platform.OS;
};
